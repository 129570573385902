import { Button, Dialog, Grid, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import './ChatbotNewFrontSection.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import chatbotWhatsappsubchat from '../../assests/ChatbotWhatsapp/cw-carousel-subImage1.svg'
import chatbotWhatsappchatBg1 from '../../assests/ChatbotWhatsapp/cw-carousel-bgimage1.svg'

import chatbotWhatsappsubchat2 from '../../assests/ChatbotWhatsapp/cw-carousel-subimage2.svg'
import chatbotWhatsappchatBg2 from '../../assests/ChatbotWhatsapp/cw-carousel-bgimage2.svg'

import chatbotWhatsappsubchat3 from '../../assests/ChatbotWhatsapp/cw-carousel-subimage3.svg'
import chatbotWhatsappchatBg3 from '../../assests/ChatbotWhatsapp/cw-carousel-bgimage3.svg'

import { Carousel } from 'react-responsive-carousel';
import BookAMeetingDialog from '../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { gsap } from "gsap";
import metaProvider from './../../assests/LandingPage/metaProvider.svg'

const ChatbotNewFrontSection = () => {
    const sliderRef = useRef(null);
    const [activeSlide, setActiveSlide] = useState(0);

    const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);

    const carouselData = [
        {
            subImage: chatbotWhatsappsubchat2,
            subImageBg: chatbotWhatsappchatBg2,
            alterText: "Chatting with property's whatapp chatbot"
        },
        {
            subImage: chatbotWhatsappsubchat3,
            subImageBg: chatbotWhatsappchatBg3,
            alterText: "Chatting with property's whatapp chatbot"
        },
        {
            subImage: chatbotWhatsappsubchat,
            subImageBg: chatbotWhatsappchatBg1,
            alterText: "Chatting with watch's whatapp chatbot"
        },   
    ]

    return (
        <>

            {flagOpenBookaMeetingDemo && (
                <Dialog open={flagOpenBookaMeetingDemo}
                    maxWidth='lg'
                    sx={{ '&.MuiPaper-root': { width: '100%' } }}
                    className="iframe-dialog"
                >
                    <BookAMeetingDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    />
                </Dialog>
            )}
            <Grid container className='front-main-section-container'>
                <Grid item xl={8.5} lg={11} md={11} sm={11} xs={12} className='front-head-text-main-item'>
                    <Grid container>
                        <Grid item xs={11} className='front-head-text-item'>
                            <Typography
                                variant='span'
                                className='front-head-normal-text-color'
                                // sx={{ fontWeight: '500', fontFamily: 'sans !important' }}
                            >
                                Instantly build Gen-AI Agents <br></br>
                            </Typography>
                            <Typography 
                                variant='span'
                                className='front-head-normal-sub-text-color' 
                            >
                                Engage, Automate, and Grow Across All Channels
                            </Typography>
                        </Grid>

                        <Grid item xs={10} sm={9} className='front-head-text-item'>
                            <Typography variant='span' className='front-head-sub-normal-text-color'>
                                {/* Discover how our AI-driven chatbot transforms conversations into conversions, enhancing user experience while optimizing  your business operations. */}
                                    Create powerful AI agents that seamlessly integrate with your website, WhatsApp, Facebook, Instagram and more. Instantly handle customer inquiries, process orders, manage returns, and automate key business actions, freeing your team to drive growth and innovation.
                            </Typography>
                        </Grid>

                        {/* <Grid item xs={12} className='front-head-btn-item'>
                            <Grid container sx={{ justifyContent: 'center', gap: '20px' }}>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        size='large'
                                        className='front-head-free-btn'
                                        // endIcon={<ArrowForwardIcon style={{ color: "white" }} />}
                                        // onClick={() => window.open(`https://scheduler.zoom.us/krishna-writewiz/one-on-one`, '_blank')}
                                        onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_self')}
                                    >
                                        Build your own Gen-AI Chatbot
                                    </Button>
                                </Grid>
                                <Grid item >
                                    <Button
                                        variant='outlined'
                                        fullWidth
                                        size='medium'
                                        className='front-head-demo-btn'
                                        onClick={() => {
                                            setFlagOpenBookaMeetingDemo(true)
                                        }}
                                    >
                                        Book a Demo
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12} className='front-head-btn-item'>
                            <Grid container sx={{ justifyContent: 'center', gap: '20px' }} direction={"row"} flexWrap={{xs: "wrap", sm: "nowrap"}}>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        size='large'
                                        className='front-head-free-btn'
                                        onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_self')}
                                    >
                                        Build your own AI Agent
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        size='large'
                                        className='front-head-free-new-btn'
                                        onClick={
                                            // () => window.open("https://scheduler.zoom.us/krishna-writewiz/one-on-one", "_blank")
                                            () => {
                                              setFlagOpenBookaMeetingDemo(true)
                                            }
                                          }
                                    >
                                        Book a Demo
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className='front-head-btn-item'>
                            <Grid container sx={{ justifyContent: 'center', gap: {xs: '20px', sm: '80px', md: '110px'} }} direction={{xs: "column", sm: "row"}} flexWrap={"nowrap"} alignItems={"center"}>
                                <Grid item>
                                    <Grid container direction={"row"} flexWrap={"nowrap"} alignItems={"center"} gap={1}>
                                        <Grid item>
                                            <span className='hash-class-styles'>#1</span>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={"column"} alignItems={"start"}>
                                                <Grid item>
                                                    <span className='verified-text-style'>Verified</span>
                                                </Grid>
                                                <Grid item>
                                                    <span className='provider-text-style'>Provider</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <img src={metaProvider} className='meta-provider-image-style'/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={11} sx={{ margin: '40px auto 20px' }}>
                            <Slider
                                dots={true}
                                infinite={true}
                                autoplay={true}
                                className='chatbot-click-front-slider'
                                speed={800}
                                arrow={false}
                                fade
                                slidesToShow={1}
                                slidesToScroll={1}
                                appendDots={dots => (
                                    <div className="dot-container">
                                        <ul className="chatbot-dots">{dots}</ul>
                                    </div>
                                )}
                            >
                                {carouselData.map((data, index) => {
                                    return (
                                        <div key={index}  className="slide" style={{ flex: "0 0 100%", display: "flex", justifyContent: "center" }}>
                                            <Grid item xs={12} sx={{ display: 'flex', height: '600px', maxHeight: '600px' }}>
                                                <Grid container sx={{ alignItems: 'center', height: 'auto', justifyContent: 'center' }}>
                                                    <Grid item className='' sx={{ position: 'absolute', zIndex: 1 }}>
                                                        <img
                                                            src={data.subImage}
                                                            style={{ width: "auto", height: '600px', objectFit: 'cover' }}
                                                            // className='cw-head-whstapp-image'
                                                            alt={data.alterText}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ position: "relative" }}>
                                                        <img
                                                            src={data.subImageBg}
                                                            style={{ width: "auto", height: '500px' }}
                                                            alt={data.alterText}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )
                                })}

                            </Slider>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}


export default ChatbotNewFrontSection