import React from 'react'
import LazyLoad from 'react-lazyload'

export default function LazyLoading(props) {

    return (

        <>
            <LazyLoad height={150} offset={100} once>
                {props.flagShow && <img src={props.src} className={props.className} style={props.style} />}
            </LazyLoad>
        </>
    )

}

