import { useEffect, useRef, useState } from "react";
import './FacebookChatbot.css'
import Navbar from "../Navbar/Navbar";
import { Grid } from "@mui/material";
import FacebookIntroSection from "../../Component/FacebookChatbot/FacebookIntroSection";
import FacebookBotFeature from "../../Component/FacebookChatbot/FacebookBotFeatures";
import ChatbotFAQs from "../../Component/ChatbotFAQs/ChatbotFAQs";
import ParaphraseOverlapFooter from "../../Component/ParaphraserSections/ParaphraseOverlapFooter";
import LandingFooter from "../../Component/Footer/LandingFooter";
// import ScrollSmoother from "gsap/ScrollSmoother";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import gsap from "gsap";
import { useLocation } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger, ScrollSmoother, useGSAP);

const FacebookChatbot = () => {
    const containerRef = useRef(null)
    const [pageName, setPageName] = useState('Chatbot');

    const [chatbotFaqs, setChatbotFaqs] = useState([
        {
            question: "How does a WhatsApp chatbot work?",
            answer: "A WhatsApp Chatbot uses AI and predefined responses to interact with users, answer queries, and perform tasks, providing a seamless experience.",
        },
        {
            question: "What are the benefits of using WhatsApp chatbots?",
            answer: "WhatsApp Chatbots improve efficiency, offer 24/7 support, and personalize interactions, leading to enhanced customer satisfaction and business growth."
        },
        {
            question: "Are WhatsApp chatbots secure?",
            answer: "WhatsApp Chatbots prioritize security, ensuring data protection and compliance with WhatsApp's policies.",
        },
        {
            question: "What can I ask the chatbot?",
            answer: "You can ask questions related to our services, product details, pricing, account management, support queries, and more. Our chatbot is designed to provide you with quick information and assist you in navigating our website."
        },
        {
            question: "How does the chatbot understand my questions?",
            answer:
                "Our chatbot uses advanced AI algorithms to understand natural language, which means you can ask questions just as you would to a human."
        },
    ])

    // useEffect(() => {
    //     const smoother = ScrollSmoother.create({
    //         content: containerRef.current,
    //         smooth: 1,
    //         effects: true,
    //         smoothTouch: 0.1,
    //     });
    //     return () => smoother.kill()
    // }, []);

    return (
        <>
            <Navbar />
            <Grid ref={containerRef} container className="fbc-main-container">
                <Grid item xs={12}><FacebookIntroSection /></Grid>
                <Grid item xs={12}><FacebookBotFeature /></Grid>
                <Grid item xs={12} className='facebool-chatbot-faq-section'>
                    <ChatbotFAQs faqData={chatbotFaqs} />
                </Grid>

                <Grid item xs={12} className='faceboor-pr-overlap-item'>
                    <ParaphraseOverlapFooter pageName={pageName} />
                </Grid>

                <Grid item xs={12} className='brsw-footer-component-main-item'>
                    <LandingFooter />
                </Grid>
            </Grid>
        </>
    )
}

export default FacebookChatbot;