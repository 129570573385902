import React, { useState } from 'react';
import "../../Component/NewScrollElement/NewScrollElement.css";
import { Grid, Typography, useMediaQuery } from "@mui/material";

import "./Chatbot.css";
import '../../Pages/Paraphraser/Paraphraser.css'


import LandingFooter from '../../Component/Footer/LandingFooter';
import Navbar from '../Navbar/Navbar';
import ChatbotHero from '../../Component/ChatbotHero/ChatbotHero';
import ChatbotExplore from "../../Component/ChatbotExplore/ChatbotExplore";
import ChatbotExploreMobile from "../../Component/ChatbotExplore/ChatbotExploreMobile"
import ChatbotGradient from '../../Component/ChatbotGradient/ChatbotGradient';
import ChatbotFeatures from '../../Component/ChatbotFeatures/ChatbotFeatures';
import ChatbotStats from '../../Component/ChatbotStats/ChatbotStats';
import ChatbotWorkFlow from '../../Component/ChatbotWorkFlow/ChatbotWorkFlow';
import ChatbotExperience from '../../Component/ChatbotExperience/ChatbotExperience';
import ChatbotCardItems from '../../Component/ChatbotCardItems/ChatbotCardItems';
import ParaphraseOverlapFooter from '../../Component/ParaphraserSections/ParaphraseOverlapFooter';
import ChatbotFAQs from '../../Component/ChatbotFAQs/ChatbotFAQs';
import ChatbotNewFrontSection from '../../Component/ChatbotNewFrontSection/ChatbotNewFrontSection';
import ChatbotWhatsappExpertiseSection from '../../Component/ChatbotWhatsappExpertiseSection/ChatbotWhatsappExpertiseSection';
import ChatbotCustomerServiceSection from '../../Component/ChatbotCustomerServiceSection/ChatbotCustomerServiceSection';
import LiveChatAISection from '../../Component/LiveChatAISection/LiveChatAISection';
import AIChatbotSimplified from '../../Component/AIChatbotSimplified/AIChatbotSimplified';
import CommonStructurePage from '../../Component/CommonStructurePage/CommonStructurePage';

import commonSection1 from './../../assests/LandingPage/commonSection1.svg';
import commonSection2 from './../../assests/LandingPage/commonSection2.svg';
import commonSection3 from './../../assests/LandingPage/commonSection3.svg';
import ChatbotCardSection from '../../Component/ChatbotCardSection/ChatbotCardSection';


const Chatbot = () => {
  // const [isBelow1200, setIsBelow1000] = useState(window.innerWidth < 1200);
  const isBelow1200 = useMediaQuery('(min-width: 0px) and (max-width: 1200px)')
  const [pageName, setPageName] = useState('Chatbot');


  const [chatbotFaqs, setChatbotFaqs] = useState([
    {
      question: "How does a WhatsApp chatbot work?",
      answer: "A WhatsApp Chatbot uses AI and predefined responses to interact with users, answer queries, and perform tasks, providing a seamless experience.",
    },
    {
      question: "What are the benefits of using WhatsApp chatbots?",
      answer: "WhatsApp Chatbots improve efficiency, offer 24/7 support, and personalize interactions, leading to enhanced customer satisfaction and business growth."
    },
    {
      question: "Are WhatsApp chatbots secure?",
      answer: "WhatsApp Chatbots prioritize security, ensuring data protection and compliance with WhatsApp's policies.",
    },
    {
      question: "What can I ask the chatbot?",
      answer: "You can ask questions related to our services, product details, pricing, account management, support queries, and more. Our chatbot is designed to provide you with quick information and assist you in navigating our website."
    },
    {
      question: "How does the chatbot understand my questions?",
      answer:
        "Our chatbot uses advanced AI algorithms to understand natural language, which means you can ask questions just as you would to a human."
    },
  ])

  const commonFormatData = [
    {
      flagwhite: false,
      formatId: 1,
      format_sub_field: "Chronox AI Chatbot",
      format_field_color: "rgba(106, 9, 125, 1)",
      format_heading: "The AI support agent for your growing business",
      format_details: ["No more frustrating workflows—just quick, accurate responses your customers will love.", "Chronox AI Chatbot handles queries instantly, understanding context to deliver precise answers without the usual back-and-forth. Elevate your customer experience with a chatbot that feels more human and less robotic."],
      format_button_text: "Discover AI Chatbots",
      btn_data: {
        btn_percentage: "76%",
        btn_data: "visitors donot transfer request to human agent"
      },
      image_data: commonSection1
    },
    {
      flagwhite: false,
      formatId: 2,
      format_sub_field: "Action Automations",
      format_field_color: "rgba(10, 106, 221, 1)",
      format_heading: "Win More Loyal Customers with Sales and Support Automation",
      format_details: ["Let your AI Chatbot take the lead—from processing orders to handling returns, our AI-powered automation seamlessly integrates with your systems, eliminating manual tasks and boosting efficiency.", "Imagine a world where routine actions happen automatically, freeing up your team to focus on what truly matters—growing your business. "],
      format_button_text: "Boost Your Efficiency Today",
      btn_data: {
        btn_percentage: "60%",
        btn_data: "businesses report higher efficiency with AI-driven automation"
      },
      image_data: commonSection2
    },
    {
      flagwhite: true,
      formatId: 3,
      format_sub_field: "Live Agent Support",
      format_field_color: "rgba(253, 182, 100, 1)",
      format_heading: "Human Touch, When It Matters Most",
      format_details: ["AI handles the routine, while live agents manage the complex—giving your customers the best of both worlds. Chronox AI allows customers to switch to a live agent when they need a personal touch, ensuring that every interaction is handled with care. Keep your customers satisfied while optimizing your support team's efforts."],
      format_button_text: "Discover Live Chat",
      btn_data: {
        btn_percentage: "58%",
        btn_data: "customers feel more confident when they know they can escalate to a human agent"
      },
      image_data: commonSection3
    },


  ]

  return (
    <Grid container>
      <Navbar />
      <Grid item xs={12}>
        <Grid container>

          <Grid item xs={12} className='brsw-new-head-section-main-item'>
            <ChatbotNewFrontSection />
          </Grid>

          <Grid item xs={12} className='brsw-new-expertise-section-main-item'>
            <ChatbotWhatsappExpertiseSection />
          </Grid>

          <Grid item xs={12} className='brsw-new-customer-section-main-item'>
            <ChatbotCustomerServiceSection />
          </Grid>

          {commonFormatData.map((format) =>
            <Grid item xs={12} className='brsw-new-common-section-main-item'>
              <Grid container>
                <CommonStructurePage format={format}/>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} className='brsw-new-liveai-section-main-item'>
            <LiveChatAISection />
          </Grid>

          {/* <Grid item xs={12} className='chatbot-card-section'>
            <ChatbotCardSection />
          </Grid> */}

          <Grid item xs={12} className='chatbot-faq-section'>
            <ChatbotFAQs faqData={chatbotFaqs} />
          </Grid>

          <Grid item xs={12} className='brsw-new-aisimplified-section-main-item'>
            <AIChatbotSimplified />
          </Grid>

          <Grid item xs={12} className='pr-overlap-item'>
            <ParaphraseOverlapFooter pageName={pageName} />
          </Grid>

          <Grid item xs={12} className='brsw-footer-component-main-item'>
            <LandingFooter />
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  )
}

export default Chatbot