import React, { useState } from 'react'
import './ChatbotCustomerServiceSection.css'
import { Grid, Typography } from '@mui/material'
import ChatbotComponentDesign from '../ChatbotCustomerDesignSection/ChatbotComponentDesign'
import demoImage from './../../assests/LandingPage/chatbotProfileAvtar.png'
import IconsColor from '../../Utils/Global/iconsColor'

const serviceData = [
    {
        serviceImage: "greet",
        serviceId: 1,
        headingDate: "Greet and Engage",
        subHeadingData: "Start every conversation like you’re welcoming an old friend—kick off with a warm, personal greeting that makes every customer feel instantly valued and recognized."
    },
    {
        serviceImage: "understand",
        serviceId: 2,
        headingDate: "Understand and Qualify",
        subHeadingData: "It’s like having a casual coffee chat where you effortlessly get to know what your customers need—no pressure, just the right questions to see if they’re a perfect fit."
    },
    {
        serviceImage: "present",
        serviceId: 3,
        headingDate: "Present and Nurture",
        subHeadingData: "It’s like knowing exactly when to share the perfect story -  introducing your product at just the right moment, so it feels like the solution they’ve been searching for all along."
    },
    {
        serviceImage: "overcome",
        serviceId: 4,
        headingDate: "Overcome Objectives",
        subHeadingData: "Handle concerns like a pro—instantly offer the right answers that make customers feel understood and confident, turning hesitation into trust without sounding like a salesperson."
    },
    {
        serviceImage: "guide",
        serviceId: 5,
        headingDate: "Guide Towards Conversion",
        subHeadingData: "Guide customers confidently to the finish line—like a smooth handshake after a great conversation, making the purchase feel like the natural next step."
    },
]

const ChatbotCustomerServiceSection = () => {
    const [selectServiceData, setSelectServiceData] = useState(serviceData[0])

    return (
        <>
            <Grid container>
                <Grid item xl={9} lg={11} md={11} sm={11} xs={11} className='service-head-text-main-item'>
                    <Grid container direction={"column"}>
                        <Grid item xs={11} className='service-head-text-item'>
                            <Typography
                                variant='span'
                                className='service-head-normal-text-color'
                                // sx={{ fontWeight: '500', fontFamily: "sans !important" }}
                                sx={{ fontWeight: '600' }}
                            >
                                Create customer service&nbsp;
                                <Typography variant='span' sx={{ fontWeight: '700' }} className='service-head-normal-dark-text-color'>
                                    that sells
                                </Typography>
                            </Typography>
                        </Grid>

                        <Grid item xs={10} sm={9} sx={{width: {xs: "90%", sm: "70%"}}} className='service-head-text-item'>
                            <Typography variant='span' className='service-head-sub-normal-text-color'>
                                It's like having your best salesperson on every call—helping customers, closing deals, and making every interaction count.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} mt={{xs: 5, lg: 11}} className='service-body-main-item'>
                            <Grid container direction={{xs: "column", md: "row"}} flexWrap={"nowrap"} alignItems={"start"} justifyContent={"space-between"} gap={4}>
                                <Grid item xs={12} lg={6.5}>
                                    {serviceData.map((data) => 
                                        <Grid container direction={"row"} flexWrap={"nowrap"} alignItems={"start"} onClick={() => setSelectServiceData(data)} className={selectServiceData.serviceId === data.serviceId ? 'selected-service-data' : 'select-service-data'} gap={2}>
                                            {/* <Grid item mt={1}>
                                                <IconsColor iconName={data.serviceImage} height={"26"} width={"26"} fillColor={selectServiceData.serviceId === data.serviceId ? "#6A097D": "#333333"}/>
                                            </Grid> */}
                                            <Grid item>
                                                <Grid direction={"column"}>
                                                    <Grid item>
                                                        <Grid container direction={"column"} gap={"10px"}>
                                                            <Grid item>
                                                                <Typography variant='span' className={selectServiceData.serviceId === data.serviceId ? 'selected-service-heading-name' : 'service-heading-name'}>{data.headingDate}</Typography>
                                                            </Grid>
                                                            <Grid item className={`${selectServiceData.serviceId === data.serviceId ? 'visible' : 'hidden'}`}>
                                                                <Typography variant='span' className={`service-heading-sub-name ${selectServiceData.serviceId === data.serviceId ? 'visible' : 'hidden'}`}>{data.subHeadingData}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>

                                <Grid item xs={12} lg={4.5} xl={4} alignSelf={"center"} className='chatbot-component-position-style'>
                                    <ChatbotComponentDesign />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ChatbotCustomerServiceSection
