import React, { useState } from 'react';
import { Grid, useMediaQuery } from "@mui/material";
import "./ChatbotFeatures.css";
import ChatbotFeaturesBox from '../ChatbotFeaturesBox/ChatbotFeaturesBox';
import RoceketIcon from "../../assests/RocketIcon.svg";
import chat from "../../assests/chat.svg";
import BarChartIcon from "../../assests/BarChartIcon.svg";
import DummyImg from "../../assests/DummyImg.jpg";
import HeaderWithUnderLine from '../HeaderWithUnderLine/HeaderWithUnderLine';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ChatbotFeatures = (props) => {
  const smallScreen = useMediaQuery('(min-width: 0px) and (max-width: 1200px)')
  const tabScreen = useMediaQuery('(min-width: 800px) and (max-width: 1200px)')


  const testData = [
    {
      logoImgLink: BarChartIcon,
      title: 'INSIGHTS',
      subTitle: 'Insights That Drive Decisions',
      description: 'Gain actionable insights from chat interactions to steer your strategy and enhance customer experiences. Data-driven success starts here.',
      mainImg: "https://storage.writewiz-staging.in/general/Insights_image_1710941311796.png",
    },
    {
      logoImgLink: chat,
      title: 'CHATS',
      subTitle: 'Chat in Any Language',
      description: 'Expand your reach with a chatbot that communicates fluently in any language, making every customer feel heard and valued.',
      mainImg: "https://storage.writewiz-staging.in/general/chats_image_1710941344034.png",
    },
    {
      logoImgLink: RoceketIcon,
      title: 'DEPLOY',
      subTitle: 'One-Click Deployment, Instantly',
      description: 'Deploy your AI chatbot across platforms with just one click. Instant integration, immediate impact',
      mainImg: "https://storage.writewiz-staging.in/general/deploy_image_1710941371070.png",
    }
  ];

  const titleText = {
    normalText: "Conversations at ",
    coloredText: "Global Scale",
    normalText2: "",
    subTitleText: "Connecting you to the world—personalized, in any language, anywhere!"
  }

  return (
    <React.Fragment>
      <Grid container className="CF-mainContainer">
        <HeaderWithUnderLine text={titleText} />
        <Grid item xl={10} lg={12} md={12} sm={12} xs={12} className="CF-featuresContainer">
          <Grid container className='CF-FeaturesBoxContainer'>
            {!smallScreen ? (testData.map((data, index) => (
              <Grid item xl={3.5} lg={3} md={5} sm={12} xs={12} className='CF-card-container' >
                <ChatbotFeaturesBox
                  key={index}
                  logoImgLink={data.logoImgLink}
                  title={data.title}
                  description={data.description}
                  mainImg={data.mainImg}
                  subTitle={data.subTitle}
                />
              </Grid>
            ))) : (
              <>
                <Carousel
                  autoPlay={true}
                  className='CF-CarouselStyles'
                  centerMode
                  centerSlidePercentage={tabScreen ? 60 : 75}
                  infiniteLoop={true}
                  autoFocus={true}
                  preventMovementUntilSwipeScrollTolerance={true}
                  swipeScrollTolerance={50}
                  showStatus={false}
                  renderIndicator={(onClickHandler, isSelected, index, label) => {
                    if (isSelected) {
                      return (
                        <li
                          style={{ background: "#6A097D", width: "30px", height: "7px", borderRadius: "8px", display: "inline-block", margin: "0 5px", cursor: "pointer" }}
                          aria-label={`Selected: ${label} ${index + 1}`}
                          title={`Selected: ${label} ${index + 1}`}
                          onClick={onClickHandler}
                          onKeyDown={onClickHandler}
                          tabIndex={0}
                          role="button"
                        />
                      );
                    }
                    return (
                      <li
                        style={{ background: "#c9c8c8", width: "7px", height: "7px", borderRadius: "50%", display: "inline-block", margin: "0 5px", cursor: "pointer" }}
                        aria-label={`${label} ${index + 1}`}
                        title={`${label} ${index + 1}`}
                        onClick={onClickHandler}
                        onKeyDown={onClickHandler}
                        tabIndex={0}
                        role="button"
                      />
                    );
                  }}
                >
                  {
                    testData.map((data, index) => (
                      <Grid item xs={11} sx={{ margin: "auto", height: "100%" }}>
                        <Grid container sx={{ padding: "15px 0px", height: "100%" }} >
                          <Grid item className='CF-card-container' >
                            <ChatbotFeaturesBox
                              key={index}
                              logoImgLink={data.logoImgLink}
                              title={data.title}
                              description={data.description}
                              mainImg={data.mainImg}
                              subTitle={data.subTitle}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  }

                </Carousel>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ChatbotFeatures;