import { Button, Grid } from '@mui/material'
import React from 'react'
import './CommonStructurePage.css'
import EastIcon from '@mui/icons-material/East';

const CommonStructurePage = (props) => {
    const {format} = props;
    const getBackgroundGredient = (formatId) => {
        switch(formatId) {
            case 1:
                return "linear-gradient(to bottom, rgba(229, 220, 239, 1), rgba(229, 220, 239, 0.6))";
            case 2:
                return "linear-gradient(to bottom, rgba(215, 228, 241, 1), rgba(215, 228, 241, 0.6))";
            default:
                return "linear-gradient(to bottom, rgba(252, 243, 233, 1), rgba(252, 243, 233, 0.6))";
        }
    }
    return (
        <Grid item sx={{margin: (format.formatId % 2) == 0 ? "80px 0px 80px 15%" : "80px 15% 80px 0px", height: "100%"}} className='main-section-common-page'>
            <Grid container className={format.flagwhite ? "common-section-white-container" : "common-section-dark-container"} sx={{borderRadius: (format.formatId % 2) == 0 ? "20px 0 0 0" : "0 20px 0 0", position: "relative", background: getBackgroundGredient(format.formatId)}}>
                <Grid item xl={10} lg={11} md={11} sm={11} xs={11} className={format.flagwhite ? 'common-section-white-item' : 'common-section-dark-item'}>
                    <Grid container direction={{xs: "column" , md: (format.formatId % 2) == 0 ? "row-reverse" : "row"}} gap={8} flexWrap={"nowrap"} justifyContent={"space-between"}>
                        <Grid item xs={12} md={8} sx={{width: "100%"}}>
                            <Grid container direction={"column"} gap={2}>
                                <Grid item>
                                    <span className='commnon-subfield-text-style' style={{color: `${format.format_field_color}`}}>{format.format_sub_field}</span>
                                </Grid>
                                <Grid item className='format-heading-item-style'>
                                    <span className='common-format-heading-style'>{format.format_heading}</span>
                                </Grid>
                                <Grid item>
                                    {format.format_details.map((value) => <span className='common-format-details-style' style={{display: "block", marginBottom: "15px"}}>{value}</span>)}
                                </Grid>
                                <Grid item className='common-section-button-components-item'>
                                    <Grid container direction={{xs: "column", md: "row"}} alignItems={"center"} gap={5} flexWrap={"nowrap"}>
                                        <Grid item>
                                            <Button className='common-format-button-styles' sx={{backgroundColor: format.format_field_color, '&:hover': {backgroundColor: format.format_field_color}}} onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_self')}>{format.format_button_text} <EastIcon  style={{marginLeft: "10px", display: "flex"}}/></Button>
                                        </Grid>
                                        <Grid item className={'common-format-btn-dark-styles'}>
                                            <Grid container direction={"row"} gap={4} flexWrap={"nowrap"} alignItems={"start"}>
                                                <Grid item>
                                                    <span className='common-format-btn-percentage'>{format.btn_data.btn_percentage}</span>
                                                </Grid>
                                                <Grid item>
                                                    <span className='common-format-btn-data'>{format.btn_data.btn_data}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3} className={(format.formatId % 2 == 0) ? 'common-section-left-image-container' : 'common-section-right-image-container'} textAlign={"center"} alignSelf={"center"}>
                                    <img src={format.image_data} className='common-section-image'/>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} md={3} className={(format.formatId % 2 == 0) ? 'common-section-left-image-container' : 'common-section-right-image-container'} textAlign={"center"} alignSelf={"center"}>
                            <img src={format.image_data} className='common-section-image'/>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CommonStructurePage
