import { Divider, Grid, Typography } from "@mui/material";
import remarkGfm from 'remark-gfm';
import rehypeExternalLinks from 'rehype-external-links';
import './ChatbotComponentDesign.css';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Markdown from "react-markdown";

export const patternToRemoveSource = /【\d+†source】/g;

export function getMessage(threadData) {
    threadData.chatbot_thread_value = threadData?.chatbot_thread_value?.replace("```json", "").replace("```", "")
    if (isJsonString(threadData.chatbot_thread_value)) {
        let thread = JSON.parse(threadData.chatbot_thread_value)
        if (thread.response) {
            thread = thread.response
        }
        if (typeof (thread) == "object") {
            if (thread.message_format_id) {
                if (thread.message_format_id == 1) {
                    return thread.message_fields.message
                }
                else if (thread.message_format_id == 4 || thread.message_format_id == 3) {
                    return thread.message_fields.url_text
                }
                else if (thread.message_format_id == 5 || Math.trunc(thread.message_format_id) == 6 || Math.trunc(thread.message_format_id) == 7) {
                    return thread.message_reply_text
                }
                else {
                    return ((thread.message) ? thread.message : "") + " " + ((thread.additional_text_message) ? thread.additional_text_message : "" + " " + ((thread.message_fields.additional_text_message ? thread.message_fields.additional_text_message : "")))
                }
            }
            else {
                return threadData.chatbot_thread_value
            }
        }
        else {
            return threadData.chatbot_thread_value
        }
    }
    else {
        return threadData.chatbot_thread_value
    }
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function getChatbotMessageTag(chatbotThread, textType) {
    switch (textType) {
        case "text":
            return chatbotThread.chatbot_thread_value
        case "image":
            return <img className="clickable"
                src={chatbotThread?.chatbot_thread_value} alt="img" style={{}} />
        // case "contact":
        //     return <ContactCard contactInfo={chatbotThread.contact_information} />
        case "location":
            return <div style={{ display: "flex", flexDirection: "column" }}><iframe src={chatbotThread?.chatbot_thread_value} title="location" style={{ border: 0 }} allowFullScreen loading="lazy" ></iframe><span><a rel="noreferrer" target='blank' href={chatbotThread?.chatbot_thread_value}>{chatbotThread?.chatbot_thread_value}</a></span></div>
        default:
            return chatbotThread.chatbot_thread_value
    }

}

export function getTag(chatbotThread, textType, initialMessagePreivewOnField) {
    let message_format_id = 1
    let thread = chatbotThread.chatbot_thread_value
    if (isJsonString(chatbotThread.chatbot_thread_value)) {
        thread = JSON.parse(chatbotThread.chatbot_thread_value)
        if (thread.response) {
            thread = thread.response
        }
        if (thread.response) {
            thread = thread.response
        }
        if (typeof (thread) == "object" && thread.message_format_id === 4) {
            message_format_id = 4
        } else {
            message_format_id = Math.trunc(thread.message_format_id)
        }
    }
    if (chatbotThread) {
        switch (textType) {
            case "text":
                return (
                    <>
                        {
                            // message_format_id === 2 ?
                            // <>
                            //     <Markdown
                            //         remarkPlugins={[remarkGfm]}
                            //         rehypePlugins={[
                            //         [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
                            //         ]}
                            //         className={`${styles.reactMarkdownStyle} ${styles.chat_reply_text}`}
                            //     >
                            //         {getMessage(chatbotThread)?.replace(patternToRemoveSource, "")}
                            //     </Markdown>
                            //     <Grid container gap="10px" mt={1}>
                            //         {JSON.parse(chatbotThread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_fields.suggestions.map((ele, i) => {
                            //             return (
                            //                 <>
                            //                     <Button
                            //                         disableRipple
                            //                         sx={{ backgroundColor: "#fff !important", color: "#000000", fontWeight: "500 !important", borderRadius: "5px !important", padding: "5px 10px !important", minWidth: "unset !important", '&:hover': { color: "", backgroundColor: "" } }}
                            //                         variant={'outlined'}
                            //                         size='small'
                            //                         // className={` ${i == flagTellusMoreOptionBtn ? 'chat-feedback-selected-btn' : 'chat-feedback-btn'}`}
                            //                     >
                            //                         {Object.values(ele)[0]}
                            //                     </Button>

                            //                 </>
                            //             )
                            //         })}
                            //     </Grid>
                            // </>
                            // :
                            // message_format_id === 5 ?
                            // <>
                            //     <Markdown
                            //         remarkPlugins={[remarkGfm]}
                            //         rehypePlugins={[
                            //         [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
                            //         ]}
                            //         className={`${styles.reactMarkdownStyle} ${styles.chat_reply_text}`}
                            //     >
                            //         {getMessage(chatbotThread)?.replace(patternToRemoveSource, "")}
                            //     </Markdown>
                            //     <Carousel suggestions={JSON.parse(chatbotThread.chatbot_thread_value).message_fields.Cards} />
                            // </>
                            // :
                            // Math.trunc(thread.message_format_id) == 6 ?
                            // <>
                            //     <Markdown
                            //         remarkPlugins={[remarkGfm]}
                            //         rehypePlugins={[
                            //         [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
                            //         ]}
                            //         className={`${styles.reactMarkdownStyle} ${styles.chat_reply_text}`}
                            //     >
                            //         {getMessage(chatbotThread)?.replace(patternToRemoveSource, "")}
                            //     </Markdown>
                            //     {<InsuranceCard data={JSON.parse(chatbotThread.chatbot_thread_value)} />}
                            // </>
                            // :
                            message_format_id !== 4
                                ?
                                <>
                                    <Markdown
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[
                                            [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
                                        ]}
                                        className="reactMarkdownStyle chat_reply_text"
                                    >
                                        {getMessage(chatbotThread)?.replace(patternToRemoveSource, "")}
                                    </Markdown>
                                </>
                                :
                                <>
                                    <div style={{ fontStyle: "italic", textDecoration: "underline", cursor: "pointer", color: "blue" }}>
                                        {getMessage(chatbotThread)?.replace(patternToRemoveSource, "")}
                                    </div>
                                </>
                        }
                    </>
                )
            case "media":
                return (
                    <>
                        {chatbotThread.media_data.map((media, index) => {
                            return (
                                <>
                                    {initialMessagePreivewOnField ? (
                                        <>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container sx={{ justifyContent: 'space-between' }}>
                                                        <Grid item className="chat_message_reply_left_item" sx={{ mt: '2px' }}>
                                                            <Typography component="span" sx={{ fontSize: '14px', fontWeight: '500', color: 'black' }}>
                                                                {index === 0 && (
                                                                    <span>
                                                                        {getMessage(chatbotThread) ? (
                                                                            <span>{getMessage(chatbotThread)}</span>
                                                                        ) : (
                                                                            <span>
                                                                                <CameraAltIcon
                                                                                    style={{
                                                                                        color: 'rgba(0, 0, 0, 0.5)',
                                                                                        verticalAlign: 'middle'
                                                                                    }}
                                                                                />
                                                                                &nbsp;Photo
                                                                            </span>
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item className="chat_img_reply_item">
                                                            <img
                                                                className={"clickable chat-reply-img"}    //here
                                                                loading={"lazy"}
                                                                src={chatbotThread.media_data.length > 1 ? chatbotThread.media_data[0].aws_storage_media_url : media.aws_storage_media_url}
                                                                alt="img"
                                                                style={{ borderRadius: '10px', width: '100%', height: '100%', objectFit: 'cover' }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            {/* OLD CODE */}
                                            <div style={{ position: "relative", cursor: "pointer" }} className="chat-reply-main-wrapper">   {/* here*/}
                                                <div
                                                    style={{
                                                        color: "#7A7A7A",
                                                        marginBottom: "0px",
                                                        fontSize: initialMessagePreivewOnField ? "14px" : '',
                                                        fontWeight: initialMessagePreivewOnField ? "500" : ''
                                                    }}
                                                >
                                                    <Markdown
                                                        remarkPlugins={[remarkGfm]}
                                                        rehypePlugins={[
                                                            [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
                                                        ]}
                                                    >{media.aws_storage_file_name}</Markdown>
                                                </div>

                                                <div className={"chat-response-div"}> {/* here */}
                                                    <img src={media.aws_storage_media_url}
                                                        alt="uploadedimage"
                                                        className={"clickable imgAWSStyle test"}
                                                        style={{ margin: '5px 0px 5px', width: "100%" }} />
                                                </div>

                                                <Divider sx={{ margin: "5px 0px" }} />
                                            </div>

                                            <div className={initialMessagePreivewOnField ? "chat_reply_meadia_text" : ''}>
                                                <Markdown
                                                    remarkPlugins={[remarkGfm]}
                                                    rehypePlugins={[
                                                        [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
                                                    ]}
                                                >
                                                    {getMessage(chatbotThread)?.replace(patternToRemoveSource, "")}
                                                </Markdown>
                                            </div>
                                        </>
                                    )}
                                </>
                            )
                        })
                        }
                    </>

                )

            case "messageReply": return (
                <>
                    {chatbotThread.media_data.map((media) => {
                        return (
                            <>
                                {media?.aws_storage_id ?
                                    (
                                        <>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container sx={{ justifyContent: 'space-between' }}>
                                                        <Grid item xs={10} className="chat_media_reply_text_item">
                                                            <Grid container>
                                                                {/* <Grid item xs={12}>
                                    <Typography variant="span" className="chat-chatbot-text-hidden-label">
                                      {chatBotDetail?.chatbot_name ? chatBotDetail.chatbot_name : ''}
                                    </Typography>
                                  </Grid> */}
                                                                <Grid item xs={12}>
                                                                    <Markdown
                                                                        remarkPlugins={[remarkGfm]}
                                                                        rehypePlugins={[
                                                                            [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
                                                                        ]}
                                                                        className="reactMarkdownStyle chat_reply_text chat_chatbot_text_hidden_label"
                                                                    >
                                                                        {chatbotThread?.chatbot_thread_value?.replace(patternToRemoveSource, "")}
                                                                    </Markdown>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={1.5} className="chat_media_reply_img_item">
                                                            <img
                                                                className={"clickable"}
                                                                loading={"lazy"}
                                                                src={media.aws_storage_media_url}
                                                                alt="img"
                                                                style={{
                                                                    borderRadius: '0px 10px 10px 0px',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'cover'
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid container>
                                                {/* <Grid item xs={12}>
                            <Typography variant="span" className="chat-chatbot-text-hidden-label">
                              {chatBotDetail?.chatbot_name ? chatBotDetail.chatbot_name : ''}
                            </Typography>
                          </Grid> */}
                                                <Grid item xs={12}>
                                                    <Markdown
                                                        remarkPlugins={[remarkGfm]}
                                                        rehypePlugins={[
                                                            [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
                                                        ]}
                                                        className="reactMarkdownStyle chat_reply_text chat_chatbot_text_hidden_label"
                                                    >
                                                        {/* {getMessage(chatbotThread)?.chatbot_thread_value?.replace(patternToRemoveSource, "")} */}
                                                        {chatbotThread?.chatbot_thread_value?.replace(patternToRemoveSource, "")}
                                                    </Markdown>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                }
                            </>
                        )
                    })}
                </>)

            // case "contact":
            //     return <ContactCard contactInfo={chatbotThread.contact_information} />
            case "location":
                return <div style={{ display: "flex", flexDirection: "column" }}><iframe src={`${chatbotThread?.chatbot_thread_value}`} title="location" style={{ border: 0 }} allowFullScreen loading="lazy" ></iframe><span><a rel="noreferrer" target='blank' href={chatbotThread?.chatbot_thread_value}>{chatbotThread?.chatbot_thread_value}</a></span></div>
            default:
                return <Markdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[
                        [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
                    ]}
                >{chatbotThread?.chatbot_thread_value}</Markdown>
        }
    }
}