import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import './ChatbotWhatsppInfo.css'
import '../../Pages/WhatsappChatbot/WhatsappChatbot.css'
import writewizLogo from '../../assests/ChatbotWhatsapp/Writewiz-Logo.svg'
import cwMultilingual from '../../assests/ChatbotWhatsapp/cw-info-multi-language.svg'
import earthLogo from '../../assests/ChatbotWhatsapp/cw-info-earth-logo.png'
import splashImg from '../../assests/ChatbotWhatsapp/wc-slash-img.svg'
import slashLogo from '../../assests/ChatbotWhatsapp/cw-slash-your-first.svg'
import reduceLogo from '../../assests/ChatbotWhatsapp/cw-reduce-repetitive -questions.svg'
import reducePercentageImg from '../../assests/ChatbotWhatsapp/cw-percentage-img.svg'
import handshakeLogo from '../../assests/ChatbotWhatsapp/cw-make-your-long-time partner.svg'
import hoverhand from '../../assests/ChatbotWhatsapp/wc-intrective-message-img.svg'
import dayLogo from '../../assests/ChatbotWhatsapp/cw-simplify-your-day.svg'
import clipboard from '../../assests/ChatbotWhatsapp/cw-clipboard.png'
import WhatsAppIcon from '../../assests/ChatbotWhatsapp/cw-wp-icon.png'
import WhatsAppBIcon from '../../assests/ChatbotWhatsapp/cw-big-wp-logo.svg'
import metaImage from '../../assests/ChatbotWhatsapp/cw-meta-provider.svg'
import task from '../../assests/ChatbotWhatsapp/cw-chat-info-icon-1.svg'
import metaVarified from '../../assests/ChatbotWhatsapp/wc-meta-img.svg'
import chatbotImg from '../../assests/ChatbotWhatsapp/wc-chatbot-img.svg'
import chatbotBg from '../../assests/ChatbotWhatsapp/cw-waves-bg.png'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ChatbotWhatsppInfo = () => {

    const [multiplatformHover, setMultiplatformHover] = useState(false);
    const [slashHover, setSlashHoverHover] = useState(false);
    const [reduceHover, setReduceHover] = useState(false);
    const [slashResponseHover, setSlashResponseHover] = useState(false)
    const [engageCustomerHover, setEngageCustomerHover] = useState(false)
    const [expanded, setExpanded] = React.useState([]);
    const isScreenWidthBelow900 = useMediaQuery('(max-width : 900px) and (min-width : 0px)')


    const faqData = [
        {
            question: "Multi-language support",
            answer:
                `Answer any question, on any live communication channel in 93 different languages.`,
            logo: cwMultilingual,

        },
        {
            question: "Rich Media Messaging",
            answer:
                `Enhance communication by sending media-rich messages, including images, videos, and documents.
                This capability allows your business to provide detailed information and visual aids that can help in explaining products,
                services, or resolving customer inquiries more effectively.`,
            logo: slashLogo,
        },
        {
            question: "Get 70% of your team’s time back",
            answer:
                `Eliminate repetitive questions from your team’s workload. Answer up to 70% of customer inquiries with human-like conversations in an instant.
                 While traditional teams have an average response time of 2 minutes, Chronox AI answers questions in under 15 seconds.`,
            logo: reduceLogo,
        },
        {
            question: "Interactive Messaging Experiences",
            answer:
                `Engage your customers with interactive messages that allow for quick and structured responses.
                Utilize tools like List Messages and Reply Buttons to guide conversations smoothly and efficiently.`,
            logo: handshakeLogo,
        },
        {
            question: "Slash your first response time",
            answer:
                "Chronox AI provides assistance to multiple customers at once, allowing your team to address all inquires in a timely manner, even during demand spikes.",
            logo: dayLogo,
        },

    ];

    const handleChange = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            setExpanded([...expanded, panel]);
        } else {
            setExpanded(expanded.filter(item => item !== panel));
        }
    };

    return (
        <>
            <Grid container>

                {!isScreenWidthBelow900 ?
                    <>
                        <Grid item xl={12} lg={11} md={11} xs={12} className='cw-info-grid-main-item'>
                            <Grid container>
                                <Grid item xs={12} className='cw-info-main-item'>
                                    <Grid container className='cw-info-main-container'>
                                        <Grid item xl={8} lg={8} md={7.5} sm={8} xs={12}>
                                            <Grid container>
                                                <Grid item xs={11} sx={{ marginTop: '10px' }}>
                                                    <img src={metaVarified} style={{ width: '150px', height: "auto", }} alt="" />
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Typography variant='span' className='cw-normal-text-color cw-info-main-head-text'>
                                                        Get Advanced Messaging & Consistent Branding with Chronox WhatsApp AI Chatbot&nbsp;
                                                        <img src={WhatsAppBIcon} style={{ width: '45px', height: "auto", verticalAlign: 'sub' }} alt="" />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xl={3.5} lg={3.5} md={3.8} sm={3.5} xs={12}
                                            className="cw-info-multiplatform-item"
                                            onMouseEnter={() => setMultiplatformHover(true)}
                                            onMouseLeave={() => setMultiplatformHover(false)}
                                        >
                                            <Grid container>
                                                <Grid item xs={11} style={{ margin: '0 auto' }}>
                                                    <Grid container style={{ gap: '5px', alignItems: 'center' }}>
                                                        <Grid item xs={2}>
                                                            <img src={cwMultilingual} style={{ width: '45px', height: "auto", verticalAlign: 'middle' }} alt="Multi-language support" />
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Typography variant='span' className='cw-info-multiplatform-text'>
                                                                Multi-language support
                                                            </Typography>
                                                        </Grid>
                                                        <Divider style={{ width: '100%', border: "1px solid #E8E8E8", margin: '5px 0px' }} />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} className='cw-info-img-item' >
                                                    <Grid container sx={{ justifyContent: 'center' }}>
                                                        <Grid item xs={12}>
                                                            <img
                                                                src={earthLogo}
                                                                alt="Earth"
                                                                className='cw-info-earth-img'
                                                                style={{
                                                                    opacity: multiplatformHover ? 0 : 1,
                                                                    // transform: hover ? "translate(100%, 0%)" : "translate(0%, 0%)",
                                                                    // transition: hover ? '' : 'opacity .6s ease-in-out,transform .3s ease-in-out'
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="span"
                                                                className='hover-text'
                                                                style={{ opacity: multiplatformHover ? 1 : 0, marginLeft: "15px" }}
                                                            >
                                                                Answer any question, on any live communication channel in 93 different languages.
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className='cw-info-slash-main-item'>
                                    <Grid container className='cw-info-slash-main-container'>
                                        <Grid item xs={12} xl={3.8} lg={3.8} md={4} sm={3.8}
                                            onMouseEnter={() => setSlashHoverHover(true)}
                                            onMouseLeave={() => setSlashHoverHover(false)}
                                            className='cw-info-slash-card-item'
                                        >
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container sx={{ alignItems: 'center', }}>
                                                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                                            <img src={slashLogo} alt="Rich Media Messaging" style={{ width: '45px', height: "auto", verticalAlign: 'middle' }} />
                                                        </Grid>
                                                        <Grid item xl={7.5} lg={7.5} md={9} sm={7.5} xs={12}>
                                                            <Typography variant='span' className='cw-info-multiplatform-text'>
                                                                Rich Media Messaging
                                                            </Typography>
                                                        </Grid>
                                                        <Divider sx={{ width: '100%', border: "1px solid #E8E8E8", margin: '5px 0px' }} />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} className='cw-info-img-item'>
                                                    <Grid container sx={{ justifyContent: 'center' }}>
                                                        <Grid item xs={12}>
                                                            <img
                                                                src={task}
                                                                alt="Earth"
                                                                className='cw-info-slash-img'
                                                                style={{
                                                                    opacity: slashHover ? 0 : 1,
                                                                    transform: slashHover ? "translate(100%, 0%)" : "translate(0%, 0%)",
                                                                    transition: slashHover ? '' : 'opacity .6s ease-in-out,transform .3s ease-in-out'
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="span"
                                                                className='hover-text'
                                                                style={{ opacity: slashHover ? 1 : 0, }}
                                                            >
                                                                Enhance communication by sending media-rich messages, including images, videos, and documents.
                                                                This capability allows your business to provide detailed information and visual aids that can help in explaining products,
                                                                services, or resolving customer inquiries more effectively.
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xl={7.8} lg={7.8} md={7.5} sm={7.8} xs={12}
                                            onMouseEnter={() => setReduceHover(true)}
                                            onMouseLeave={() => setReduceHover(false)}
                                            className='cw-info-reduce-card-item'
                                        >
                                            <Grid container >
                                                <Grid item xs={12}>
                                                    <Grid container sx={{ alignItems: 'center' }}>
                                                        <Grid item xs={1}>
                                                            <img
                                                                src={reduceLogo}
                                                                style={{ width: '45px', height: "auto", verticalAlign: 'middle' }} alt="Reduce repetitive questions" />
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Typography variant='span' className='cw-info-multiplatform-text'>
                                                                Get 70% of your team’s time back
                                                            </Typography>
                                                        </Grid>
                                                        <Divider style={{ width: '100%', border: "1px solid #E7E7E7", margin: '5px 0px' }} />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sx={{ marginTop: reduceHover ? "0px" : "70px", marginBottom: '-15px', overflow: "hidden" }} className='cw-info-img-item'>
                                                    <Grid container sx={{ justifyContent: 'center' }}>
                                                        <Grid item xs={12}>
                                                            <img
                                                                src={reducePercentageImg}
                                                                alt=""
                                                                className='cw-infor-reduce-img'
                                                                style={{
                                                                    opacity: reduceHover ? 0 : 1,
                                                                    transform: reduceHover ? "translate(0%, 100%)" : "translate(0%, 0%)",
                                                                    transition: reduceHover ? '' : 'opacity .10s ease-in-out,transform .3s ease-in-out'
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="span"
                                                                className='hover-text'
                                                                style={{ opacity: reduceHover ? 1 : 0, alignItems: "start" }}
                                                            >
                                                                Eliminate repetitive questions from your team’s workload. Answer up to 70% of customer inquiries with human-like conversations in an instant.
                                                                While traditional teams have an average response time of 2 minutes, Chronox AI answers questions in under 15 seconds.
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className='cw-info-partner-main-item'>
                                    <Grid container className='cw-info-partner-main-container'>
                                        <Grid item xl={7.8} lg={7.8} md={7.8} sm={7.8} xs={12}
                                            className='cw-info-partner-card-item'
                                            onMouseEnter={() => setEngageCustomerHover(true)}
                                            onMouseLeave={() => setEngageCustomerHover(false)}
                                        >
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container sx={{ alignItems: "center" }}>
                                                        <Grid item xs={1}>
                                                            <img
                                                                src={handshakeLogo}
                                                                style={{
                                                                    width: '45px',
                                                                    height: "auto",
                                                                    verticalAlign: 'middle'
                                                                }}
                                                                alt="Engage your customers with interactive messages"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Typography variant='span' className='cw-info-partner-text'>
                                                                Interactive Messaging Experiences
                                                            </Typography>
                                                        </Grid>
                                                        <Divider sx={{ width: '100%', border: "1px solid #E8E8E8", margin: '5px 0px' }} />
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant='span'
                                                                // className='hover-text'
                                                                style={{
                                                                    opacity: engageCustomerHover ? 1 : 0,
                                                                    transform: engageCustomerHover ? "translate(100%, 0%)" : "translate(0%, 0%)",
                                                                    transition: engageCustomerHover ? '' : 'opacity .6s ease-in-out,transform .3s ease-in-out',
                                                                    fontSize: '15px', fontWeight: '500'
                                                                }}
                                                            >
                                                                Engage your customers with interactive messages that allow for quick and structured responses.
                                                                Utilize tools like List Messages and Reply Buttons to guide conversations smoothly and efficiently.
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} className='cw-info-img-item'>
                                                    <img
                                                        src={hoverhand}
                                                        style={{
                                                            opacity: engageCustomerHover ? 0 : 1,
                                                        }}
                                                        alt="Engage your customers with interactive messages"
                                                        className='cw-info-guage-img'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} xl={3.8} lg={3.8} md={3.8} sm={3.8} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <img
                                                src={chatbotImg}
                                                alt="Engage your customers with interactive messages"
                                                style={{
                                                    width: '200px',
                                                    height: 'auto',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className='cw-info-clipboard-main-item'>
                                    <Grid container className='cw-info-clipboard-main-container'>
                                        <Grid item xs={12} xl={3.8} lg={3.8} md={3.8} sm={3.8}
                                            className='cw-info-day-card-item'
                                            onMouseEnter={() => setSlashResponseHover(true)}
                                            onMouseLeave={() => setSlashResponseHover(false)}
                                        >
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container sx={{ alignItems: 'center' }}>
                                                        <Grid item xs={2}>
                                                            <img src={dayLogo} style={{ width: '45px', height: "auto", verticalAlign: 'middle' }} alt="" />
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Typography variant='span' className='cw-info-multiplatform-text'>
                                                                Slash your first response time
                                                            </Typography>
                                                        </Grid>
                                                        <Divider sx={{ width: '100%', border: "1px solid #E8E8E8", margin: '5px 0px' }} />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} className='cw-info-img-item'>
                                                    <Grid container sx={{ justifyContent: 'center' }}>
                                                        <Grid item xs={12}>
                                                            <img
                                                                src={splashImg}
                                                                alt="Slash your first response time"
                                                                className='cw-info-slash-img'
                                                                style={{
                                                                    opacity: slashResponseHover ? 0 : 1,
                                                                    transform: slashResponseHover ? "translate(100%, 0%)" : "translate(0%, 0%)",
                                                                    transition: slashResponseHover ? '' : 'opacity .6s ease-in-out,transform .3s ease-in-out'
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="span"
                                                                className='hover-text'
                                                                style={{ opacity: slashResponseHover ? 1 : 0, }}
                                                            >
                                                                Chronox AI provides assistance to multiple customers at once, allowing your team to address all inquires in a timely manner, even during demand spikes.
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                                {/* <Grid item xs={12} className='cw-info-clip-item'>
                                    <Grid container sx={{ justifyContent: 'center' }}>
                                        <Grid item xs={12}>
                                            <Grid container sx={{ justifyContent: 'center' }}>
                                                <Grid item xs={12}>
                                                    <img
                                                        src={splashImg}
                                                        alt=""
                                                        className='cw-info-clip-img'
                                                        style={{
                                                            opacity: slashResponseHover ? 0 : 1,
                                                            transform: slashResponseHover ? "translate(100%, 0%)" : "translate(0%, 0%)",
                                                            transition: slashResponseHover ? '' : 'opacity .6s ease-in-out,transform .3s ease-in-out'
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="span"
                                                        className='hover-text'
                                                        style={{ opacity: slashResponseHover ? 1 : 0, marginLeft: "15px" }}
                                                    >
                                                        Answer any question, on any live communication channel in 93 different languages.
                                                    </Typography>
                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                            </Grid>
                                        </Grid>

                                        <Grid item xl={7.8} lg={7.8} md={7.8} sm={7.8} xs={12}>
                                            <Typography variant='span' className='cw-info-clip-head-text'>
                                                Chronox Chatbot provides Conversational AI&nbsp;
                                                <Typography variant='span' className='cw-info-clip-head-highlioght-text'>
                                                    that talks to your customers and answers their questions using only your knowledge base.
                                                </Typography>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </> :
                    <>
                        <Grid item xs={11} sx={{ margin: '0 auto' }}>
                            <Grid container >
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={11} sx={{ marginTop: '10px' }}>
                                            <img src={metaVarified} style={{ width: '150px', height: "auto", }} alt="" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant='span' className='cw-normal-text-color cw-info-main-head-text'>
                                                Get Advanced Messaging & Consistent Branding with Chronox WhatsApp AI Chatbot&nbsp;
                                                <img
                                                    src={WhatsAppBIcon}
                                                    className='cw-info-image'
                                                    alt=""
                                                />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className='pp-faq-accordion-item'>
                                    {faqData.map((item, index) => (
                                        <Accordion
                                            key={index}
                                            expanded={expanded.includes(`panel${index}`)}
                                            onChange={handleChange(`panel${index}`)}
                                            className="wpc-accordion"
                                        >
                                            <AccordionSummary
                                                className="wpc-expand-accordion-summary"
                                                expandIcon={<ExpandMoreIcon className="expand-icon" />}
                                                aria-controls={`panel${index}bh-content`}
                                                id={`panel${index}bh-header`}
                                            >
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Grid container sx={{ alignItems: 'center', gap: "10px" }}>
                                                            <Grid item>
                                                                <img src={item.logo} style={{ width: '30px', height: 'auto', verticalAlign: 'middle' }} alt="" />
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <Typography className="pp-faq-ques-head">{item.question}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails className="wpc-details-section">
                                                {item.answer.split('\n').map((it) => {
                                                    return (
                                                        <Typography className="pp-faq-ans-text" sx={{ marginBottom: "1rem" }}>
                                                            {it}
                                                        </Typography>
                                                    )
                                                })}
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
            </Grid>
        </>
    )
}

export default ChatbotWhatsppInfo