import { Button, Dialog, Divider, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import './ChatbotWhatsappExpertiseSection.css';
import openAi from '../../assests/ChatbotWhatsapp/cw-expertise-openai.svg';
import cloud from '../../assests/ChatbotWhatsapp/cw-expertise-cloud.svg';
import meta from '../../assests/ChatbotWhatsapp/cw-expertise-meta.svg';

const ChatbotWhatsappExpertiseSection = () => {
    return (
        <>
            <Grid container>
                <Grid item xl={6.5} lg={9} md={10} sm={10} xs={11} className='cw-expertise-text-main-item'>
                    <Grid container direction={"column"} alignItems={"center"} gap={"50px"}>
                        <Grid item xs={12}>
                            <Typography variant='span' className='cw-expertise-normal-text-color'>
                                Proven expertise with
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sx={{width: "100%"}} mt={2}>
                            <Grid container direction={"row"} flexWrap={{xs: "wrap", md:"nowrap"}} justifyContent={"space-around"} alignItems={"center"} gap={{xs: 5, md: 0}}>
                                <Grid item>
                                    <img src={openAi} style={{maxHeight: "50px"}}/>
                                </Grid>
                                <Grid item>
                                    <img src={cloud} style={{maxHeight: "50px"}}/>
                                </Grid>
                                <Grid item>
                                    <img src={meta} style={{maxHeight: "50px"}}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={9} sx={{width: "100%"}}>
                            <Divider />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>    
    )
}


export default ChatbotWhatsappExpertiseSection