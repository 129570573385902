import React, { useContext, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Context as mainContext } from './Context/MainContext/GlobalContext'
import Chatbot from "./Pages/Chatbot/Chatbot";
import WhatsappChatbot from "./Pages/WhatsappChatbot/WhatsappChatbot";
import ChatbotPricing from "./Pages/ChatbotPricing/ChabotPricing";
import ContactSales from "./Pages/ContactSales/ContactSales";
import ChatbotFeatures from "./Component/ChatbotFeatures/ChatbotFeatures";
import ChronoxChatbot from "./Pages/ChronoxChatbot/ChronoxChatbot";
import AiChatbot from "./Component/AIChatbot/AiChatbot";
import FacebookPage from "./Pages/FacebookChatbot/FacebookChatbot";

const RoutePage = () => {

  const [isBelow1000, setIsBelow1000] = useState(window.innerWidth < 1000);
  const { userDetails, loading, toastNotification } = useContext(mainContext).state
  const { setUserDetails, setToastNotification } = useContext(mainContext)
  const location = useLocation();
  const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const dialogFlag = queryParams.get('meeting_flag') === 'true';
    setTimeout(() => {
      setFlagOpenBookaMeetingDemo(dialogFlag);
    }, 1000);
  }, [location]);

  console.log("userDetails", userDetails);
  console.log("toastNotification", toastNotification);


  return (
    <>
      {/* {loading && <Loader />} */}

      {/* {
        flagOpenBookaMeetingDemo &&
        <Dialog open={flagOpenBookaMeetingDemo}
          maxWidth='lg'
          sx={{ '&.MuiPaper-root': { width: '100%' } }}
          className="iframe-dialog"
        >
          <BookAMeetingDialog
            flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
            setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
          />
        </Dialog>
      } */}

      {/* {toastNotification.status &&
        <Snackbar open={true} autoHideDuration={8000} onClose={handleNotificationClose}>
          <Alert onClose={handleClose} severity={toastNotification.error == true ? "error" : "success"} >
            {toastNotification.message}
          </Alert>
        </Snackbar>
      } */}

      <Routes>
        <Route path="/" element={<Chatbot />} />
        <Route path="/chatbot" element={<Chatbot />} />
        <Route path="/ai-chatbot" element={<AiChatbot />} />
        <Route path="/whatsapp-ai-chatbot" element={<WhatsappChatbot />} />
        <Route path="/facebook-ai-chatbot" element={<FacebookPage />} />
        <Route path="/chatbot-pricing" element={<ChatbotPricing />} />
        <Route path="/contact-us" element={<ContactSales />} />

        {/* <Route path="/chatbot-feature" element={<ChronoxChatbot />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};


export default RoutePage;
