import React, { useEffect, useState } from "react";
import "./Navbar.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { alpha, Dialog, Fab, Grid, Menu, MenuItem, styled, useMediaQuery } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import metaProvider from '../../assests/LandingPage/meta-provider.svg'
import BookAMeetingDialog from "../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog";
// import WritewizaiPrimaryLogo from "../../assests/GlobalImages/WritewizAI-Primary-Logo.svg"
// import WritewizaiWhiteLogo from "../../assests/GlobalImages/WritewizAI-White-Logo.svg"
import chronoxWhiteLogo from "../../assests/GlobalImages/Chronox-White-Logo.svg"
import ChronoxPrimaryLogo from "../../assests/LandingPage/ChronoxNewLogo.svg"
import menu from "../../assests/GlobalImages/Navbar-menu.svg"
import whatsppimage from '../../assests/Whatsapp.svg'
import botIcon from './../../assests/LandingPage/chatbotProfileAvtar.png'
import messangerimage from '../../assests/Chronox-integration.svg'
import messengerIcon from './../../assests/FacebookChatbot/facebookMessenger.svg'


const Navbar = (props) => {

  const drawerWidth = "100%";
  const writeWizWebsiteURL = process.env.REACT_APP_WEBSITE_REDIRECT_URL;
  const webPageName = window.location.pathname.replace('/', '')

  console.log("webPageName", webPageName);

  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [flagOpenHelpCenterDialog, setFlagOpenHelpCenterDialog] = useState(false);
  const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [isOnHoverNavbar, setIsOnHoverNavbar] = useState(false);
  const [isOnHoverIntegration, setisOnHoverIntegration] = useState(false);
  const [flagPopoverHover, setFlagPopoverHover] = useState(false);
  const isTablateWidth = useMediaQuery('(min-width: 0px) and (max-width: 900px')

  const MenuProps = {
    PaperProps: {
      style: {
        // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    disableScrollLock: true
  };

  useEffect(() => {
    window.addEventListener("scroll", changeStructure);
    return () => {
      window.removeEventListener("scroll", changeStructure);
    };
  }, []);

  const changeStructure = () => {
    if (window.scrollY > 90) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      className="mobileHamburgerSideBar"
      sx={{ textAlign: "center", width: "100%" }}
    >
      <div className="mobileHamburgerSideBar-inner-div">
        <div className="mobileHamburgerSideBar-logo-container">
          <div style={{ display: "flex", alignItems: "center" }}>
            <a onClick={() => { navigate('/') }} target="_self" style={{ lineHeight: 0.8, marginLeft: '40px' }}>
              <img
                src={chronoxWhiteLogo}
                color="white"
                alt="Chronox"
                width={"auto"}
                marginTop={'10px !important'}
                height={40}
              />
            </a>
          </div>

          <Fab
            className="mobileHamburgerSideBar-back-button"
            color="primary"
            aria-label="add"
          >
            <ArrowBackIosNewIcon sx={{ transform: "rotate(180deg)" }} />
          </Fab>
        </div>

        <div className="mobileHamburgerSideBar-center-box">
          <div className="mobileHamburgerSideBar-button" onClick={() => navigate("/")}>
            Home
          </div>

          <div className="mobileHamburgerSideBar-button" onClick={() => navigate("/whatsapp-ai-chatbot")}>
            WhatsApp Chatbot
          </div>

          <div className="mobileHamburgerSideBar-button" onClick={() => navigate("/chatbot-pricing")}>
            Pricing
          </div>

          <div className="mobileHamburgerSideBar-button" onClick={() => navigate("/contact-us")}>
            Contact Us
          </div>

          <div className="mobileHamburgerSideBar-button" onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, "_self")}>
            Start Writing For free
          </div>

          <div className="mobileHamburgerSideBar-button" onClick={() => setFlagOpenBookaMeetingDemo(true)}>
            Book a Meeting
          </div>
          <div className="mobileHamburgerSideBar-footer-parent">
            <div className="mobileHamburgerSideBar-footer-right" style={{ marginLeft: "40px" }}>
              {/* <div onClick={() => window.open("https://app.chronox.ai/privacy-policy", "_blank")}>Privacy Policy</div>
              <div onClick={() => window.open("https://app.chronox.ai/terms", "_blank")}>T&C</div> */}
              <div onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}/privacy-policy`, `_blank`)}>Privacy Policy</div>
              <div onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}/terms`, `_blank`)}>T&C</div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );

  return (
    <>
      <div className="main-navbar-wrapper">
        {flagOpenBookaMeetingDemo && (
          <Dialog open={flagOpenBookaMeetingDemo}
            maxWidth='lg'
            sx={{ '&.MuiPaper-root': { width: '100%' } }}
            className="iframe-dialog"
          >
            <BookAMeetingDialog
              flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
              setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
            />
          </Dialog>
        )}

        {isOnHoverIntegration ?
          <>
            <Grid container
              onMouseEnter={() => { setFlagPopoverHover(true); setisOnHoverIntegration(true) }}
              onMouseLeave={() => { setFlagPopoverHover(false); setisOnHoverIntegration(false) }}
              className={`navbar-hover-menu-container`}
            >
              <Grid item xs={12} className="navbar-hover-menu-item">
                hello
              </Grid>
            </Grid>
          </> : <></>
        }

        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            component="nav"
            className={isScroll ? "wz-header-scroll" : "wz-header"}
            sx={{
              padding: "0px 3px",
              alignItems: "center",
              // "& .MuiToolbar-root": {
              //   width: "93%",
              // },
            }}
          >
            <Toolbar className="wz-header-toolbar" sx={{ overflow: "hidden", paddingInline: "15px !important" }}>
              <Grid container sx={{ alignItems: 'center' }} justifyContent={"space-between"} gap={3} flexWrap={"nowrap"}>
                <Grid item
                  // xs={4}
                  xs={isTablateWidth ? 12 : 2}
                  sx={{ display: isTablateWidth ? 'flex' : '' }}
                >

                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
                    className={props.scrollEffect ? "wz-header-logo-text-after-scroll" : "wz-header-logo-text-before-scroll"}
                  >
                    <div style={{ display: "flex", alignItems: "center" }} className="wz-mainlogo-container">
                      <a onClick={() => { navigate('/') }} target="_self" style={{ lineHeight: 0.8 }}>
                        <img
                          src={ChronoxPrimaryLogo}
                          alt="Chronox"
                          width={"auto"}
                          marginTop={'10px !important'}
                          style={{cursor: "pointer"}}
                          height={"auto"}
                          className="chronox-logo"
                        />
                      </a>

                      {/* <img
                        src={metaProvider}
                        alt="meta"
                        width={"auto"}
                        marginTop={'10px !important'}
                        style={{marginLeft: "20px"}}
                        height={"auto"}
                        className="meta-logo"

                      /> */}
                    </div>
                  </Typography>


                  <Grid item xs={9} sx={{ display: 'flex', justifyContent: isTablateWidth ? 'end' : '' }}>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="end"
                      className="navbar-drawer-button"
                      onClick={handleDrawerToggle}
                      sx={{ mr: 2, color: "black" }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>

                </Grid>

                {!isTablateWidth ?
                    <Grid item xs={9} md={9.5} xl={10}>
                      <Box
                        className={
                          props.page ? props.scrollEffect ? "navbar-computer-right-button-group" : "navbar-computer-right-button-group-with-hidden-button" : "navbar-computer-right-button-group-without-transition"
                        }
                        sx={{ overflow: !props.scrollEffect ? "visible" : "hidden" }}
                      >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Grid item>
                            <Grid container>
                              {/* <Grid item>
                                <Button
                                  id="demo-customized-button"
                                  aria-controls={open ? 'demo-customized-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? 'true' : undefined}
                                  variant="text"
                                  disableElevation
                                  onClick={handleClick}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  className="option-navbar-btn"
                                >
                                  Integrations
                                </Button>
                                <Menu
                                  id="demo-customized-menu"
                                  MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                  }}
                                  PaperProps={{
                                    style: {
                                      width: 300,
                                      borderRadius: '10px',
                                      padding: '15px 25px'
                                    },
                                  }}
                                  disableScrollLock={true}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                >
                                  <Grid item xs={12} >
                                    <Grid container>
                                      <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                                        <span className="navbar-heading-text">
                                          Platform and Integrations:
                                        </span>
                                      </Grid>
                                      <Grid item xs={12} className="navbar-option-item">
                                        <Grid container sx={{ alignItems: 'center' }}>
                                          <Grid item>
                                            <img src={whatsppimage} style={{ width: '25px', height: 'auto', verticalAlign: 'sub' }} alt="" />
                                          </Grid>
                                          <Grid item sx={{ flex: 1, pl: '10px' }} onClick={() => { navigate('/whatsapp-ai-chatbot') }}>
                                            <span className="navbar-options-text">
                                              WhatsApp Chatbot
                                            </span>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                </Menu>
                              </Grid> */}

                              {/* <Grid item>
                                <Button
                                  variant="text"
                                  className="option-navbar-btn"
                                  onMouseLeave={() => setIsOnHoverNavbar(false)}
                                  onMouseEnter={() => setIsOnHoverNavbar(true)}
                                  onClick={() => { navigate('/chatbot-pricing') }}
                                >
                                  Pricing
                                </Button>
                              </Grid> */}

                              {/* <Grid item>
                                <Button
                                  variant="text"
                                  className="option-navbar-btn"
                                  onMouseLeave={() => setIsOnHoverNavbar(false)}
                                  onMouseEnter={() => setIsOnHoverNavbar(true)}
                                  onClick={() => { navigate('/contact-us') }}
                                >
                                  Contact us
                                </Button>
                              </Grid> */}

                              <Grid item>
                              <Button
                                  id="demo-customized-button"
                                  aria-controls={open ? 'demo-customized-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? 'true' : undefined}
                                  variant="text"
                                  disableElevation
                                  onClick={handleClick}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  className="option-navbar-btn"
                                >
                                  Platform
                                </Button>
                                <Menu
                                  id="demo-customized-menu"
                                  MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                  }}
                                  PaperProps={{
                                    style: {
                                      width: 300,
                                      borderRadius: '10px',
                                      padding: '0px 15px',
                                      minHeight: "0"
                                    },
                                  }}
                                  disableScrollLock={true}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                >
                                  <Grid item xs={12} >
                                    <Grid container direction={"column"}>
                                      <Grid item xs={12} className="navbar-option-item">
                                        <Grid container sx={{ alignItems: 'center' }}>
                                          <Grid item>
                                            <img src={botIcon} style={{ width: '25px', height: 'auto', verticalAlign: 'middle', display: "flex", borderRadius: "50%" }} alt="" />
                                          </Grid>
                                          <Grid item sx={{ flex: 1, pl: '10px', pt: 0.5 }} onClick={() => { navigate('/ai-chatbot') }}>
                                            <span className="navbar-options-text">
                                              AI Chatbot
                                            </span>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12} className="navbar-option-item">
                                        <Grid container sx={{ alignItems: 'center' }}>
                                          <Grid item>
                                            <img src={whatsppimage} style={{ width: '25px', height: 'auto', verticalAlign: 'middle', display: "flex" }} alt="" />
                                          </Grid>
                                          <Grid item sx={{ flex: 1, pl: '10px', pt: 0.5 }} onClick={() => { navigate('/whatsapp-ai-chatbot') }}>
                                            <span className="navbar-options-text">
                                              WhatsApp Chatbot
                                            </span>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      {/* <Grid item xs={12} className="navbar-option-item">
                                        <Grid container sx={{ alignItems: 'center' }}>
                                          <Grid item>
                                            <img src={messengerIcon} style={{ width: '24px', height: 'auto', verticalAlign: 'middle', display: "flex" }} alt="" />
                                          </Grid>
                                          <Grid item sx={{ flex: 1, pl: '10px', pt: 0.5 }} onClick={() => { navigate('/facebook-ai-chatbot') }}>
                                            <span className="navbar-options-text">
                                              Facebook Messenger
                                            </span>
                                          </Grid>
                                        </Grid>
                                      </Grid> */}
                                    </Grid>
                                  </Grid>

                                </Menu>
                              </Grid>

                              <Grid item>
                                <Button
                                  variant="text"
                                  className="option-navbar-btn"
                                  onMouseLeave={() => setIsOnHoverNavbar(false)}
                                  onMouseEnter={() => setIsOnHoverNavbar(true)}
                                  onClick={() => { navigate('/') }}
                                >
                                  Solutions
                                </Button>
                              </Grid>

                              <Grid item>
                                <Button
                                  variant="text"
                                  className="option-navbar-btn"
                                  onMouseLeave={() => setIsOnHoverNavbar(false)}
                                  onMouseEnter={() => setIsOnHoverNavbar(true)}
                                  onClick={() => { navigate('/') }}
                                >
                                  Industries
                                </Button>
                              </Grid>

                              <Grid item>
                                <Button
                                  variant="text"
                                  className="option-navbar-btn"
                                  onMouseLeave={() => setIsOnHoverNavbar(false)}
                                  onMouseEnter={() => setIsOnHoverNavbar(true)}
                                  onClick={() => { navigate('/chatbot-pricing') }}
                                >
                                  Pricing
                                </Button>
                              </Grid>

                              {/* <Grid item>
                                <Button
                                  variant="text"
                                  className="option-navbar-btn"
                                  onMouseLeave={() => setIsOnHoverNavbar(false)}
                                  onMouseEnter={() => setIsOnHoverNavbar(true)}
                                  onClick={() => { navigate('/') }}
                                >
                                  Resources
                                </Button>
                              </Grid> */}

                              <Grid item>
                                <Button
                                  variant="text"
                                  className="option-navbar-btn"
                                  onMouseLeave={() => setIsOnHoverNavbar(false)}
                                  onMouseEnter={() => setIsOnHoverNavbar(true)}
                                  onClick={() => { navigate('/contact-us') }}
                                >
                                  Contact
                                </Button>
                              </Grid>

                            </Grid>
                          </Grid>


                          <Grid item>
                            <Grid container sx={{ gap: '10px' }}>
                              <Grid item>
                                <Button
                                  key={"Get Demo"}
                                  variant={"outlined"}
                                  sx={{
                                    marginLeft: "10px !important",
                                    minWidth: "40px",
                                    color: '#6A097D',
                                    background: 'white',
                                    borderRadius: '5px',
                                    border: '1px solid #6A097D',
                                    fontSize: '15px',
                                    fontWeight: '600',
                                    textTransform: 'none',
                                    padding: '5px 20px'
                                  }}
                                  onClick={
                                    // () => window.open("https://scheduler.zoom.us/krishna-writewiz/one-on-one", "_blank")
                                    () => {
                                      setFlagOpenBookaMeetingDemo(true)
                                    }
                                  }
                                >
                                  Book a Meeting
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  key={"Blog"}
                                  variant={"text"}
                                  sx={{
                                    marginLeft: "10px !important",
                                    minWidth: "40px",
                                    color: 'white',
                                    background: '#6A097D',
                                    borderRadius: '5px',
                                    border: '1px solid #6A097D',
                                    fontSize: '15px',
                                    fontWeight: '600',
                                    textTransform: 'none',
                                    padding: '5px 20px',
                                  }}
                                  className="navbar-contact-us"
                                  onClick={(event) => {
                                    const url = process.env.REACT_APP_REDIRECT_URL;
                                    if (event.ctrlKey || event.metaKey) {
                                      window.open(url, "_blank");
                                    } else {
                                      window.location.href = url;
                                    }
                                  }}
                                >
                                  Get Started
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                    </Grid> : (<></>)
                }

              </Grid>
            </Toolbar>
          </AppBar>

          <Box component="nav" className="mobileHamburgerSideBar-parent-div">
            <div>
              <Drawer
                variant="persistent"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                anchor="right"
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                    backgroundColor: "#6A097D",
                  },
                }}
              >
                {drawer}
              </Drawer>
            </div>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Navbar;
