import React, { useContext, useEffect, useState } from 'react'
import './ChatbotPricing.css'
import { Alert, Grid, Snackbar } from '@mui/material'
import ChatbotPricingHeadSection from '../../Component/ChatbotPricingHeadSection/ChatbotPricingHeadSection'
import Navbar from '../Navbar/Navbar'
import ChatbotPricingCardSection from '../../Component/ChatbotPricingCardSection/ChatbotPricingCardSection'
import LandingFooter from '../../Component/Footer/LandingFooter'
import ChatbotWhatsappCustomizeSection from '../../Component/ChatbotWhatsappCustomizeSection/ChatbotWhatsappCustomizeSection'
import ChatbotPricingCustomeSection from '../../Component/ChatbotPricingCustomeSection/ChatbotPricingCustomeSection'
import ChatbotPricingFAQSection from '../../Component/ChatbotPricingFAQSection/ChatbotPricingFAQSection'
import { Context as mainContext } from '../../Context/MainContext/GlobalContext'
import ChatbotFAQs from '../../Component/ChatbotFAQs/ChatbotFAQs'


const ChatbotPricing = () => {

    const { setLoader, setUserDetails } = useContext(mainContext)
    const { userDetails } = useContext(mainContext).state

    // CHATBOT PRICING FAQ COMPONENT NOT USED BELOW IS DYNAMIC
    const faqData = [
        {
            question: "How does a whatsApp chatbot work?",
            answer: "A WhatsApp Chatbot uses AI and predefined responses to interact with users, answer queries, and perform tasks, providing a seamless experience.",
        },
        {
            question: "What are the benefits of using WhatsApp chatbots?",
            answer: "WhatsApp Chatbots improve efficiency, offer 24/7 support, and personalize interactions, leading to enhanced customer satisfaction and business growth."
        },
        {
            question: "Are WhatsApp chatbots secure?",
            answer: "WhatsApp Chatbots prioritize security, ensuring data protection and compliance with WhatsApp's policies.",
        },
        {
            question: "What can I ask the chatbot?",
            answer: "You can ask questions related to our services, product details, pricing, account management, support queries, and more. Our chatbot is designed to provide you with quick information and assist you in navigating our website."
        },
        {
            question: "How does the chatbot understand my questions?",
            answer:
                "Our chatbot uses advanced AI algorithms to understand natural language, which means you can ask questions just as you would to a human."
        },
    ];

    const [chatbotPricingDetails, setChatbotPricingDetails] = useState([])
    const [pricingDropdownValue, setPricingDropdownValue] = useState({
        chatbot_plan_amount: 0,
        chatbot_plan_feature_chats: 0
    })
    const [pricingInputDetails, setPricingInputDetails] = useState([])
    const [alertMessage, setAlertMessage] = useState({
        status: false,
        message: "",
        error: "",
    });
    const [selectedAmountAndHours, setSelectedAmountAndHours] = useState({
        hours: null,
        amount: null,
        visible: false,
        hoursId: null
        // visibleFree: false,  // Control visibility for Free plan
        // visiblePremium: false  // Control visibility for Premium plan
    })
    const [selectedAmountAndImages, setSelectedAmountAndImages] = useState({
        images: null,
        amount: null,
        visible: false,
        imagesId: null
        // visibleFree: false,  // Control visibility for Free plan
        // visiblePremium: false  // Control visibility for Premium plan
    })
    const [basePrices, setBasePrices] = useState({
        free: null,
        premium: null
    });
    const [totalPrices, setTotalPrices] = useState({
        free: null,
        premium: null
    });


    const handleClose = () => {
        setAlertMessage({
            status: false,
            message: "",
            error: "",
        })
    };

    useEffect(() => {
        setLoader(true)
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_CALL_URL + '/pricing/chatbot/chatbot-plans');
                const addonFeatureResponse = await fetch(process.env.REACT_APP_API_CALL_URL + '/pricing/chatbot/chatbot-addon-features')

                const jsonData = await response.json();
                if (jsonData.status) {
                    console.log("Response of get chatbot plan API:", jsonData);

                    let premiumPlan = jsonData.data.find(plan => plan.chatbot_plan_type_name === "Premium");
                    if (premiumPlan && premiumPlan.chatbot_plan && premiumPlan.chatbot_plan.length > 0) {
                        setPricingDropdownValue({
                            ...pricingDropdownValue,
                            chatbot_plan_amount: Number(premiumPlan?.chatbot_plan[0]?.chatbot_plan_amount),
                            chatbot_plan_feature_chats: Number(premiumPlan?.chatbot_plan[0]?.chatbot_plan_feature_chats)
                        });
                    }

                    const plans = jsonData.data;
                    let newBasePrices = { free: 0, premium: 0 };
                    plans.forEach(plan => {
                        if (plan.chatbot_plan_type_name === "Free") {
                            newBasePrices.free = plan.chatbot_plan[0].chatbot_plan_amount;  // Assuming first entry is the relevant one
                        } else if (plan.chatbot_plan_type_name === "Premium") {
                            newBasePrices.premium = plan.chatbot_plan[0].chatbot_plan_amount;  // Assuming first entry is the relevant one
                        }
                    });

                    setBasePrices(newBasePrices);
                    setTotalPrices(newBasePrices);

                    manageGenericCallKeys(jsonData.data)

                    setChatbotPricingDetails(jsonData.data)
                    setLoader(false)
                }
                else {
                    console.log("False Response of get chatbot plan API:", jsonData);
                    setLoader(false)
                    setAlertMessage({
                        status: true,
                        message: "Something Went Wrong!",
                        error: true,
                    })
                }

                const jsonAddonFeatureResponse = await addonFeatureResponse.json();
                if (jsonAddonFeatureResponse.status) {
                    console.log("jsonAddonFeatureResponse.data", jsonAddonFeatureResponse.data);
                    manageGenericCallKeys(jsonData.data, jsonAddonFeatureResponse.data)

                    // Extract features
                    const audioFeature = jsonAddonFeatureResponse.data.find(feature => feature.chatbot_addon_features_name == "Audio Input");
                    const imageFeature = jsonAddonFeatureResponse.data.find(feature => feature.chatbot_addon_features_name == "Image Input");

                    // Set initial state for audio input if available
                    if (audioFeature && audioFeature.chatbot_addon_features_price.length > 0) {
                        setSelectedAmountAndHours({
                            hours: audioFeature.chatbot_addon_features_price[0].chatbot_addon_features_value,
                            amount: audioFeature.chatbot_addon_features_price[0].chatbot_addon_features_price_amount,
                            visible: false,
                            hoursId: null,
                            // visibleFree: false,
                            // visiblePremium: false
                        });
                    }

                    // Set initial state for image input if available
                    if (imageFeature && imageFeature.chatbot_addon_features_price.length > 0) {
                        setSelectedAmountAndImages({
                            images: imageFeature.chatbot_addon_features_price[0].chatbot_addon_features_value,
                            amount: imageFeature.chatbot_addon_features_price[0].chatbot_addon_features_price_amount,
                            visible: false,
                            imagesId: null,
                            // visibleFree: false,
                            // visiblePremium: false
                        });
                    }

                    setPricingInputDetails(jsonAddonFeatureResponse.data);
                    setLoader(false)
                }
                else {
                    console.log("false Response of get chatbot plan API:", jsonAddonFeatureResponse);
                    setLoader(false)
                    setAlertMessage({
                        status: true,
                        message: "Something Went Wrong!",
                        error: true,
                    })
                }

            }
            catch (error) {
                console.log("error", error);
                setAlertMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
                setLoader(false)
            }
        };

        fetchData();
    }, []);

    const manageGenericCallKeys = (planData) => {
        console.log("planData", planData);
    }


    return (
        <>
            <Navbar />
            {alertMessage.status &&
                <Snackbar open={true} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={alertMessage.error == true ? "error" : "success"} sx={{ width: '100%' }}>
                        {alertMessage.message}
                    </Alert>
                </Snackbar>
            }
            <Grid container>
                <Grid item xs={12} className='cp-main-head-sec-item'>
                    <ChatbotPricingHeadSection />
                </Grid>

                <Grid item xs={12} className='cp-main-card-sec-item'>
                    <ChatbotPricingCardSection
                        chatbotPricingDetails={chatbotPricingDetails}
                        setChatbotPricingDetails={setChatbotPricingDetails}

                        pricingDropdownValue={pricingDropdownValue}
                        setPricingDropdownValue={setPricingDropdownValue}

                        pricingInputDetails={pricingInputDetails}
                        setPricingInputDetails={setPricingInputDetails}

                        setSelectedAmountAndHours={setSelectedAmountAndHours}
                        selectedAmountAndHours={selectedAmountAndHours}

                        selectedAmountAndImages={selectedAmountAndImages}
                        setSelectedAmountAndImages={setSelectedAmountAndImages}

                        basePrices={basePrices}
                        setBasePrices={setBasePrices}

                        totalPrices={totalPrices}
                        setTotalPrices={setTotalPrices}
                    />
                </Grid>

                <Grid item xs={12} className='cw-custom-main-item'>
                    <ChatbotPricingCustomeSection />
                </Grid>

                <Grid item xs={12} className='cp-faq-main-item'>
                    <ChatbotFAQs faqData={faqData} />
                </Grid>

                <Grid item xs={12} sx={{ margin: '50px 0px 0px' }}>
                    <LandingFooter />
                </Grid>
            </Grid>
        </>
    )
}

export default ChatbotPricing