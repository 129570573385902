import React, { useEffect, useLayoutEffect, useRef } from "react";
import { Grid, Typography, Box } from "@mui/material";
import "./FacebookChatBotGlobal.css"
import firstImage from "../../assests/ChatbotWhatsapp/cw-chat-first-card-1.svg"
import secondImage from '../../assests/ChatbotWhatsapp/cw-chat-second-card-1.svg'
import thirdImage from '../../assests/ChatbotWhatsapp/cw-chat-third-card-1.svg'
import messanger from '../../assests/messenger.svg'
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(SplitText)

const groupSection = [
    {
        mainHeading: "Scale Engagement and Support with Facebook Chatbot",
        content: "  The finest chatbot for Facebook Messenger can help you reach a wider audience and engage with all of your visitors throughout the day. To gain context, gather consumer data, and answer with human-like interactions, use conversational AI that is developed using the best Natural Language Processing algorithms.",
        leftSideImage: true,
        image: firstImage,
        altText: 'Connect with all plateforms and data-driven insights',
        points: [
            { label: 'Real-time connectivity', img: messanger },
            { label: 'Data-driven insights', img: messanger },
            { label: 'Smart WhatsApp decision', img: messanger },
        ]
    },
    {
        mainHeading: "Support Customers All the Time",
        content: "  The finest chatbot for Facebook Messenger can help you reach a wider audience and engage with all of your visitors throughout the day. To gain context, gather consumer data, and answer with human-like interactions, use conversational AI that is developed using the best Natural Language Processing algorithms.",
        leftSideImage: false,
        image: secondImage,
        altText: 'Easy interaction with AI and human entities',
        points: [
            { label: 'Seamless Toggles', img: messanger },
            { label: 'AI-assisted prompts', img: messanger },
            { label: 'Ensure detail accuracy', img: messanger },
        ]
    },
    {
        mainHeading: "Utilise the Best Facebook Chatbot to Reengage Customers!",
        content: "  The finest chatbot for Facebook Messenger can help you reach a wider audience and engage with all of your visitors throughout the day. To gain context, gather consumer data, and answer with human-like interactions, use conversational AI that is developed using the best Natural Language Processing algorithms.",
        leftSideImage: true,
        image: thirdImage,
        altText: 'Auto-generated message templates for your Tasks & Messaging',
        points: [
            { label: 'Automatic WhatsApp messages', img: messanger },
            { label: 'Brand-specific templates', img: messanger },
            { label: 'Personalized message templates', img: messanger },
        ]
    },

]
const FacebookBotFeature = () => {

    const featureContentRef = useRef(groupSection.map(() => React.createRef()))
    const featureImagesRef = useRef(groupSection.map(() => React.createRef()))
    const featureHeadingContRef = useRef([])
    const childrenRefs = useRef([])

    useLayoutEffect(() => {
        childrenRefs.current = []
        featureContentRef?.current.forEach((ref, index) => {
            const childElementsArray = Array.from(ref.current.children);
            childElementsArray.forEach(((element, ind) => {
                gsap.fromTo(
                    element,
                    { opacity: 0, y: 50 },
                    {
                        opacity: 1,
                        delay: ind * 0.1,
                        y: 0,
                        duration: 1,
                        ease: 'power4.out',
                        scrollTrigger: {
                            trigger: element,
                            start: 'top 100%',
                            toggleActions: 'play reverse play reverse',
                            scrub: true,
                        },
                    }
                );
            }))

        });

        featureImagesRef.current.forEach((ref, index) => {
            gsap.fromTo(ref.current,
                { opacity: 0, y: 100, scale: 0.8 },
                {
                    opacity: 1, y: 0, scale: 1, duration: 0.8, ease: 'power4.out', scrollTrigger: {
                        trigger: ref.current,
                        start: 'top 100%',
                        toggleActions: 'play reverse play reverse',
                        scrub: true,
                    }
                }
            )
        })

        const splitText = Array.from(featureHeadingContRef.current.children)
        const timeline = gsap.timeline()
        splitText.forEach((element, ind) => {
            const textChars = new SplitText(element, { type: "lines" });
            timeline.from(
                textChars.lines,
                {
                    opacity: 0,
                    duration: 0.5,
                    delay: ind * 0.1,
                    yPercent: 100,
                    ease: 'power4.inOut',
                    stagger: 0.2,
                }
            );
        })
        return () => {
            splitText.forEach((element) => {
                const textChars = new SplitText(element, { type: "chars, words" });
                textChars.revert();
            });
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);


    return (
        <Grid container className="outer-wrapper m-bottom-top ">
            <Grid item xs={12}>
                <Grid ref={featureHeadingContRef} container className="fbf-title-container" >
                    <Typography sx={{ margin: 'auto !important' }} className="main-sub-heading" variant="h2">
                        Delightful Support Experience With Chatbot On Facebook Messanger
                    </Typography>
                    <Typography sx={{ margin: '25px auto !important' }} className="sub-para-content" variant="body1">
                        Respond to Custmers with personalised conversations in real-time using conversational AI
                        chatbot for FB. Be available for your customer 24x7.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {
                    groupSection?.map((feturesData, ind) => {
                        const reverseRow = !feturesData.leftSideImage
                        return (
                            <Grid key={ind} container className={`fbf-features-container ${reverseRow && "row-reverse"}`}>
                                <Box ref={featureImagesRef.current[ind]} className='fbf-right-content-box'>
                                    <img src={feturesData?.image} alt={feturesData.altText} className="fbf-features-img" />
                                </Box>
                                <Box ref={featureContentRef.current[ind]} className={`fbf-left-content-box ${reverseRow ? 'padding-right' : 'padding-left'}`}>
                                    <Typography className="fbf-main-heading" variant="h3">
                                        {feturesData.mainHeading}
                                    </Typography>
                                    <Typography variant="body1" className="para-content">
                                        {feturesData.content}
                                    </Typography>
                                    <Grid container sx={{ marginTop: { xs: '25px', sm: '50px' } }} >
                                        {feturesData.points.map((points, ind) => (
                                            <Grid key={ind} item className="fbf-feature-grid-item" >
                                                <Grid container sx={{ justifyContent: "center" }}>
                                                    <Grid item ><img src={points.img} alt={points.label} className="fbf-points-icon" /></Grid>
                                                    <Grid item xs={12} sx={{ textAlign: "center" }} >
                                                        <Typography className="fbf-point-text">
                                                            {points.label}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Grid>
    )
}

export default FacebookBotFeature;