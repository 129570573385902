import { Button, Dialog, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import './ChatbotWhatsappHeadSection.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import chatbotWhatsappsubchat from '../../assests/ChatbotWhatsapp/cw-carousel-subImage1.svg'
import chatbotWhatsappchatBg1 from '../../assests/ChatbotWhatsapp/cw-carousel-bgimage1.svg'

import chatbotWhatsappsubchat2 from '../../assests/ChatbotWhatsapp/cw-carousel-subimage2.svg'
import chatbotWhatsappchatBg2 from '../../assests/ChatbotWhatsapp/cw-carousel-bgimage3.svg'

import chatbotWhatsappsubchat3 from '../../assests/ChatbotWhatsapp/cw-carousel-subimage3.svg'
import chatbotWhatsappchatBg3 from '../../assests/ChatbotWhatsapp/cw-carousel-bgimage2.svg'

import { Carousel } from 'react-responsive-carousel';
import BookAMeetingDialog from '../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const ChatbotWhatsappHeadSection = () => {


    const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);

    const carouselData = [
        {
            subImage: chatbotWhatsappsubchat,
            subImageBg: chatbotWhatsappchatBg1,
            alterText: "Chatting with watch's whatapp chatbot"
        },
        {
            subImage: chatbotWhatsappsubchat2,
            subImageBg: chatbotWhatsappchatBg2,
            alterText: "Chatting with property's whatapp chatbot"
        },
        {
            subImage: chatbotWhatsappsubchat3,
            subImageBg: chatbotWhatsappchatBg3,
            alterText: "Chatting with property's whatapp chatbot"
        },
    ]

    return (
        <>

            {flagOpenBookaMeetingDemo && (
                <Dialog open={flagOpenBookaMeetingDemo}
                    maxWidth='lg'
                    sx={{ '&.MuiPaper-root': { width: '100%' } }}
                    className="iframe-dialog"
                >
                    <BookAMeetingDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    />
                </Dialog>
            )}
            <Grid container>
                <Grid item xl={8.5} lg={11} md={11} sm={11} xs={12} className='cw-head-text-main-item'>
                    <Grid container>
                        <Grid item xs={11} className='cw-head-text-item'>
                            <Typography
                                variant='span'
                                className='cw-normal-text-color'
                                sx={{ fontSize: "37px", fontWeight: '600', }}
                            >
                                Empower Your Interactions with&nbsp;
                                <Typography
                                    variant='span'
                                    sx={{ background: '#008069', color: 'white', padding: '0px 5px', }}
                                >WhatsApp AI</Typography>&nbsp;
                                Chatbot that
                                thinks, responds, and take actions in real-time
                            </Typography>
                        </Grid>

                        <Grid item xs={9} className='cw-head-text-item'>
                            <Typography variant='span' sx={{ fontSize: '19px', fontWeight: '500', color: '#1F384C' }}>
                                From handling support tickets, organizing team tasks, and analyzing customer feedback, to tracking shipments,
                                resolving delivery issues, and generating actionable insights, you are covered
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className='cw-head-btn-item'>
                            <Grid container sx={{ justifyContent: 'center', gap: '20px' }}>
                                <Grid item >
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        size='large'
                                        className='cw-head-free-btn'
                                        endIcon={<ArrowForwardIcon style={{ color: "white" }} />}
                                        // onClick={() => window.open(`https://scheduler.zoom.us/krishna-writewiz/one-on-one`, '_blank')}
                                        onClick={() => {
                                            setFlagOpenBookaMeetingDemo(true)
                                        }}
                                    >
                                        Schedule a Meeting
                                    </Button>
                                </Grid>
                                <Grid item >
                                    <Button
                                        variant='outlined'
                                        fullWidth
                                        size='medium'
                                        className='cw-head-demo-btn'
                                        onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_self')}
                                    >
                                        Build your own AI Chatbot
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* <Grid item xs={12} className='cw-head-wp-img-item'>
                            <Carousel
                                showArrows={false}
                                autoPlay
                                className='CarouselStyles'
                                infiniteLoop={true}
                                autoFocus={true}
                                preventMovementUntilSwipeScrollTolerance={true}
                                swipeScrollTolerance={30}
                                showStatus={false}
                                showThumbs={false}
                                swipeable
                                useKeyboardArrows
                                renderIndicator={(onClickHandler, isSelected, index, label) => {
                                    if (isSelected) {
                                        return (
                                            <li
                                                style={{ background: "#6A097D", width: "50px", height: "7px", borderRadius: "8px", display: "inline-block", margin: "0 5px", cursor: "pointer" }}
                                                aria-label={`Selected: ${label} ${index + 1}`}
                                                title={`Selected: ${label} ${index + 1}`}
                                                onClick={onClickHandler}
                                                onKeyDown={onClickHandler}
                                                tabIndex={0}
                                                role="button"
                                            />
                                        );
                                    }
                                    return (
                                        <li
                                            style={{ background: "#c9c8c8", width: "50px", height: "7px", borderRadius: "8px", display: "inline-block", margin: "0 5px", cursor: "pointer" }}
                                            aria-label={`${label} ${index + 1}`}
                                            title={`${label} ${index + 1}`}
                                            onClick={onClickHandler}
                                            onKeyDown={onClickHandler}
                                            tabIndex={0}
                                            role="button"
                                        />
                                    );
                                }}
                            >
                                {carouselData.map((data) => {
                                    return (
                                        <div style={{ width: "100%", maxWidth: '100%', overflow: "hidden" }}>

                                            <Grid item xs={12} sx={{ display: 'flex', height: '600px', maxHeight: '600px' }}>
                                                <Grid container sx={{ alignItems: 'center', height: 'auto', justifyContent: 'center' }}>
                                                    <Grid item className='' sx={{ position: 'absolute', zIndex: 1, }}>
                                                        <img
                                                            src={data.subImage}
                                                            style={{ width: "auto", height: '600px', objectFit: 'cover' }}
                                                            // className='cw-head-whstapp-image'
                                                            alt={data.alterText}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ position: "relative", }}>
                                                        <img
                                                            src={data.subImageBg}
                                                            style={{ width: "auto", height: '470px' }}
                                                            alt={data.alterText}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>

                                    )
                                })}
                            </Carousel>


                        </Grid> */}

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={11} sx={{ margin: '0 auto' }}>
                            <Slider
                                dots={true}
                                infinite={true}
                                autoplay={true}
                                className='chatbot-click-slider'
                                speed={400}
                                arrow={false}
                                slidesToShow={1}
                                slidesToScroll={1}
                                appendDots={dots => (
                                    <div className="dot-container">
                                        <ul className="chatbot-dots">{dots}</ul>
                                    </div>
                                )}
                            >
                                {carouselData.map((data) => {
                                    return (
                                        <Grid container sx={{ position: 'relative', height: { xs: '500px', sm: "600px", md: '724px' } }}>
                                            <img src={data.subImageBg} alt={data.alterText} className='cw-slider-bg-img' />
                                            <img src={data.subImage} alt={data.alterText} className='cw-slider-whatsapp-img' />
                                        </Grid>
                                    )
                                })}
                            </Slider>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}


export default ChatbotWhatsappHeadSection