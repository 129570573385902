import React, { useState } from 'react'
import './ChatbotComponentDesign.css'
import { Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { getChatbotMessageTag, getTag, isJsonString } from './ChatbotComponent';
import botIcon from '../../assests/LandingPage/chatbotProfileAvtar.png';
import powerdByLogo from '../../assests/LandingPage/ChronoxLogo.svg';
import conversation from '../../assests/LandingPage/conversation.png';
import sendMessage from '../../assests/LandingPage/sendMessage.png';

let currentActiveChatbotThread = [
    {
        "chatbot_thread_id": 1298,
        "chatbot_thread_value": "Hi Sarah! Welcome back to Chronox AI! 😊 How can I assist you today",
        "chatbot_thread_seq": "1",
        "flag_user_message": false,
        "chatbot_route_source": "Website",
        "receiver_phone_number": "",
        "timestamp": "2024-03-27 05:46:33",
        "ip_address": "110.227.201.47",
        "message_type": "text",
        "reaction_code": "",
    },
    {
        "chatbot_thread_id": 1299,
        "chatbot_thread_value": "Hello",
        "chatbot_thread_seq": "1",
        "flag_user_message": true,
        "chatbot_route_source": "Website",
        "receiver_phone_number": "",
        "timestamp": "2024-03-27 05:46:33",
        "ip_address": "110.227.201.47",
        "message_type": "text",
        "reaction_code": "",
    }
]

const ChatbotComponentDesign = () => {
    const [iframeKey, setIframeKey] = useState(0);

    return (
        <>
            <Grid item className="cbPreviewIframeItem">
                <div
                    key={iframeKey}
                    style={{ minHeight: "calc(100% - 220px) !important" }}
                    className="cbPreviewIframeParentDiv"
                // dangerouslySetInnerHTML={{ __html: chatbotThemeDetails?.chatbot_iframe ? chatbotThemeDetails.chatbot_iframe : "" }}
                >
                    <Box>
                        <Grid container
                            sx={{
                                bgcolor: "#FFFFFF",
                                position: "relative",
                                borderRadius: "15px",
                                boxShadow: "0px 0px 5px 0px rgba(106, 9, 125, 0.8)",
                            }}
                        >
                            <Grid item xs={12} sx={{borderRadius: "15px"}}>
                                <Grid container sx={{ overflow: "hidden" }}>
                                    <Grid item xs={12} className="chatbotNavbarMainItem">
                                        <Grid container
                                            sx={{
                                                bgcolor: "#F7E9FF",
                                                p: "0px 10px",
                                                height: '60px',
                                                alignItems: "center",
                                                borderRadius: "15px 15px 0px 0px",
                                                border: 'none',
                                                direction: "auto"
                                            }}
                                        >
                                            <Grid item xs={11}>
                                                <Grid container alignItems={"center"} gap="10px">
                                                    <Grid item>
                                                        <img
                                                            src={botIcon}
                                                            alt="chatbot Icon"
                                                            style={{ width: "40px", height: "40px", objectFit: "cover", display: 'flex', borderRadius: "50%" }}
                                                        />
                                                    </Grid>

                                                    <Grid item sx={{ display: "flex" }}>
                                                        <Typography
                                                            component={'span'}
                                                            sx={{
                                                                fontWeight: 600,
                                                                fontSize: "15px",
                                                                display: "flex",
                                                                color: "#000000"
                                                            }}
                                                        >
                                                            {/* {chatbotThemeDetails?.chatbot_name} */}
                                                            ToyGenius
                                                            {/* Test Chatbot */}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <>
                                        <Grid item xs={12}>
                                            <Grid container id="messageSenderDiv" direction={"column"} sx={{ flexWrap: "nowrap !important", padding: "10px !important", direction: "auto" }} overflow={"auto"}
                                                //   ref={containerRef} 
                                                className="chatbotChatMainSectionItem"
                                                height={"50vh"}
                                            >

                                                {(currentActiveChatbotThread !== null && currentActiveChatbotThread.length > 0 && currentActiveChatbotThread[0].chatbot_thread_id && currentActiveChatbotThread[0].chatbot_thread_id !== "") ?

                                                    currentActiveChatbotThread?.length ? currentActiveChatbotThread?.map((thread) => {
                                                        const isBot = thread?.flag_user_message == false
                                                        // const isBot = !thread?.flag_user_message
                                                        return (
                                                            <>
                                                                {isBot ? (
                                                                    <>
                                                                        <Grid item sx={{ width: '100%', textAlign: 'start', margin: "12px 0px" }}>
                                                                            <Grid container sx={{ alignItems: "center", justifyContent: "start", flexWrap: "nowrap" }}>

                                                                                <Grid item
                                                                                    sx={{
                                                                                        background: "#FFFFFF"
                                                                                    }}
                                                                                    className="chatbotChatIconDiv"
                                                                                >
                                                                                    <img src={botIcon}
                                                                                        alt="chatbot Icona"
                                                                                        style={{ width: "36px", height: "36px", objectFit: 'fill', borderRadius: "50%" }} />
                                                                                </Grid>

                                                                                <Grid item sx={{ maxWidth: "calc(100% - 75px)" }}>
                                                                                    <Grid container direction={"column"}>
                                                                                        <Grid item
                                                                                            className="parentbotMsgBoxStyle"
                                                                                            sx={{
                                                                                                direction: "initial",
                                                                                                marginLeft: "6px",
                                                                                                padding: "10px 10px",
                                                                                                borderRadius: "0px 8px 8px 8px",
                                                                                                // boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.15)",
                                                                                                boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 2px 0px",
                                                                                                fontSize: "15px",
                                                                                                fontWeight: "500",
                                                                                                width: "100%",
                                                                                                maxWidth: "calc(100% - 75px)",
                                                                                                backgroundColor: "#F7E9FF",
                                                                                            }}
                                                                                        >
                                                                                            <pre
                                                                                                className="botMsgBoxStyle"
                                                                                                style={{
                                                                                                    color: "#000000",
                                                                                                    overflow: "unset",
                                                                                                    width: "100%",
                                                                                                    // textWrap: "pretty",
                                                                                                    whiteSpace: "normal",
                                                                                                    wordBreak: "break-word",
                                                                                                    maxWidth: "unset",
                                                                                                    background: "unset !important",
                                                                                                }}
                                                                                            >
                                                                                                {getTag(thread, "text")}
                                                                                            </pre>
                                                                                            {thread.reaction_code && <p className="reactionStyle">&#x{thread.reaction_code};</p>}

                                                                                        </Grid>

                                                                                        {isJsonString(thread.chatbot_thread_value) && typeof JSON.parse(thread.chatbot_thread_value) == "object" && (JSON.parse(thread.chatbot_thread_value).message_format_id) == 2 &&
                                                                                            <Grid container gap="10px" mt={1} marginLeft={1}>
                                                                                                {JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_fields.suggestions.map((ele, i) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <Button
                                                                                                                key={i}
                                                                                                                disableRipple
                                                                                                                sx={{ backgroundColor: "#fff", color: "#000000 !important" }}
                                                                                                                // sx={{ backgroundColor: "#fff !important", color: "#000000", fontWeight: "500 !important", borderRadius: "5px !important", padding: "5px 10px !important", minWidth: "unset !important", '&:hover': { color: "", backgroundColor: "" } }}
                                                                                                                variant={'outlined'}
                                                                                                                size='small'
                                                                                                            // className={` ${i == flagTellusMoreOptionBtn ? 'chat-feedback-selected-btn' : 'chat-feedback-btn'}`}
                                                                                                            >
                                                                                                                {Object.values(ele)[0]}
                                                                                                            </Button>

                                                                                                        </>
                                                                                                    )
                                                                                                })}
                                                                                            </Grid>
                                                                                        }

                                                                                        {/* {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && (JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 5 &&
                                                                                            <Grid item xs={12} sx={{ maxWidth: "calc(100% - 80px)", width: "inherit" }}>
                                                                                                <Carousel suggestions={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_fields.Cards} />
                                                                                            </Grid>
                                                                                        } */}


                                                                                        {/* {isJsonString(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) && typeof JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")) == "object" && Math.trunc(JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", "")).message_format_id) == 6 &&
                                                                                            <Grid item sx={{ maxWidth: "calc(100% - 80px)", width: "inherit" }}>
                                                                                                <InsuranceCard data={JSON.parse(thread.chatbot_thread_value.replace("```json", "").replace("```", ""))} chatbotTheme={chatBotDetails?.chatbot_theme} />
                                                                                            </Grid>
                                                                                        } */}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Grid item sx={{ width: '100%', textAlign: 'start', margin: "12px 0px" }}>
                                                                            <Grid container sx={{ alignItems: "center", justifyContent: "end", flexWrap: "nowrap" }}>
                                                                                <Grid item
                                                                                    sx={{
                                                                                        marginRight: "6px",
                                                                                        padding: "8px 8px",
                                                                                        borderRadius: "8px 8px 0px 8px",
                                                                                        // boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.15)",
                                                                                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 2px 0px",
                                                                                        fontSize: "15px",
                                                                                        fontWeight: "500",
                                                                                        backgroundColor: "#FFFFFF",
                                                                                    }}
                                                                                >
                                                                                    <Typography
                                                                                        sx={{
                                                                                            color: "#000000",
                                                                                            wordBreak: 'break-all',
                                                                                            fontSize: "14px"
                                                                                        }}
                                                                                    >
                                                                                        {getChatbotMessageTag(thread, thread.message_type,)}
                                                                                    </Typography>
                                                                                    {thread.reaction_code && <p className="reactionStyle">&#x{thread.reaction_code};</p>}
                                                                                    
                                                                                </Grid>
                                                                                {/* <Grid item sx={{ marginLeft: "6px", alignSelf: "end" }}>
                                                                                    <img alt="User Icon" src={userIcon} style={{ width: "40px", height: "auto", objectFit: "fill", display: 'flex' }} />
                                                                                </Grid> */}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                )
                                                                }
                                                            </>
                                                        )
                                                    }) : ""
                                                    :
                                                    <>
                                                        <Grid item className="fullHeight">
                                                            <Grid container className="fullHeight centerAlignment" direction={"column"}>
                                                                <Grid item>
                                                                    <img src={conversation} alt="conversation" />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography className="primaryFontSize" sx={{ color: "#000000" }}>No Conversation here yet.</Typography></Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} padding={"0px 10px"}>
                                            <Grid container
                                                sx={{ direction: "auto" }}
                                                gap="10px" direction={"row"} flexWrap={"nowrap"} alignItems={"center"} justifyContent={"center"}>
                                                <Grid item xs={12} className="chatbotPreviewMessageboxMainItem"
                                                // sx={{ marginBottom: chatbotThemeDetails.show_powered_by_chronox !== null || chatbotThemeDetails.show_powered_by_chronox ? "20px !important" : "" }}
                                                >
                                                    <Grid container
                                                        sx={{
                                                            background: "#FBF4FF",
                                                            border: "1px solid rgba(106,9,125,0.2)",
                                                            boxShadow: "0 1px 2px rgba(106,9,125,0.2)"
                                                        }}
                                                        className="chatbotPreviewMessageboxMainContainer"
                                                    >
                                                        <TextField
                                                            variant="outlined"
                                                            className="sendMsgTextField"
                                                            sx={{
                                                                border: 'none',
                                                                "& fieldset": {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    color: "#7A7A7A !important",
                                                                },
                                                                '& .Mui-disabled': {
                                                                    '-webkit-text-fill-color': "#7A7A7A !important",
                                                                }
                                                            }
                                                            }
                                                            placeholder="Send a message..."
                                                            disabled={true}
                                                            fullWidth
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end" sx={{ backgroundColor: "rgba(247, 233, 255, 0.5)" }}>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item className="chatbot_preview_arrow_item" sx={{
                                                    backgroundColor: "#FBF4FF"
                                                }}>
                                                    <IconButton
                                                        sx={{
                                                            border: "1px solid rgba(106,9,125,0.2)",
                                                            // boxShadow: `0px 0px 1px ${chatbotThemeDetails?.chatbot_send_message_icon_color ? chatbotThemeDetails?.chatbot_send_message_icon_color : chatBotDetails?.chatbot_theme.chatbot_send_message_icon_color}`,
                                                            borderRadius: '8px',
                                                            height: '100%',
                                                            width: '100%',
                                                            padding: '5px'
                                                        }}
                                                    >
                                                        <img src={sendMessage} height={"18"} width={"18"} />
                                                        
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ padding: '0px 10px', margin: '10px 10px 10px', direction: "ltr" }}>
                                            <Typography
                                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: " 13px !important",
                                                        fontWeight: "500 !important",
                                                        color: "#989696 !important",
                                                        margin: "2px 10px 0px !important",
                                                        display: "flex !important",
                                                    }}
                                                    className="chat-poweredby-text">
                                                    Powered by
                                                </Typography>
                                                {/* <a href={process.env.REACT_APP_CHRONOX_LANDING_PAGE_URL} target="_blank" style={{ cursor: 'pointer' }}> */}
                                                    <img
                                                        src={powerdByLogo}
                                                        className="chat-powered-logo"
                                                        style={{
                                                            width: "100px",
                                                            height: "auto",
                                                            objectFit: "fill",
                                                            verticalAlign: "sub",
                                                        }}
                                                        alt="Chronox AI"
                                                    />
                                                {/* </a> */}
                                            </Typography>
                                        </Grid>
                                    </>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                </div>
            </Grid>
        </>
    )
}

export default ChatbotComponentDesign
